import { ReactNode } from "react";
import { cn } from "../utils";

export function DefaultLayout({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "tw-mx-auto tw-w-full tw-max-w-[1400px] tw-p-2 tw-pt-24",
        className,
      )}
    >
      {children}
    </div>
  );
}
