import { cn } from "@jammable/ui-core";
import { getUrlForSrc } from "../Media";
import spinner from "./spinner.webp";

export function ConversionSpinner() {
  return (
    <div className="tw-flex tw-h-48 tw-justify-center tw-p-2">
      <img
        src={getUrlForSrc(spinner)}
        width={"192px"}
        height={"192px"}
        alt="loading..."
      />
    </div>
  );
}

export function Spinner({
  className,
  size = 8,
}: {
  className?: string;
  size?: number;
}) {
  return (
    <div
      role="status"
      className={`tw-relative tw-size-${size} tw-flex tw-items-center tw-justify-center`}
    >
      <div
        style={{ borderTopColor: "transparent" }}
        className={cn(
          `tw-absolute tw-size-${size} tw-animate-spinner-ease-spin tw-rounded-full tw-border-2 tw-border-solid tw-border-purple-700 tw-border-x-transparent tw-border-t-transparent`,
          className,
        )}
      ></div>
      <span className="tw-sr-only">Loading...</span>
    </div>
  );
}
