import { ObjectId } from "mongodb";
import mongoose, { Schema, Document, Model } from "mongoose";

interface Info {
  acapella: string;
  model: string;
}

export interface ConversionDocument extends Document {
  userid: string;
  modelid?: ObjectId;
  modelIds: ObjectId[];
  jobid?: string;
  originalUrl: string;
  convertedUrl: string; // vocals
  combinedUrl?: string; // (not for tts) instrumental + vocals
  instrumentalUrl?: string;
  status?: string;
  conversionStatus?: string;
  conversionType?: string;
  info: Info;
  public: boolean;
  shortid: string;
  name?: string;
  downloads: number;
  showcase: boolean;
  youtubeUrl?: string;
  ip?: string;
  mode?: string;
  failCount: number;
  toolMode: {
    isToolMode: boolean;
    type?: string;
  };
  deleted: boolean;
  isFree: boolean;
  schemaVersion: number;
  createdAt: Date;
  updatedAt: Date;
  voiceTimes: [];
  userPlanName?: string;
  duration: number;
  clip?: {
    inTime?: number;
    outTime?: number;
    duration?: number;
  };
  librarySongId?: ObjectId;
  settings?: {
    addEffects?: boolean;
    extractBackground?: boolean;
    pitch?: any[];
    highQuality?: boolean;
    levelDb?: number;
    skipExtraction?: boolean;
    swapRate?: number;
    autotune?: {
      enabled?: boolean;
      strength?: number;
    };
  };
}

const ConversionSchema = new Schema<ConversionDocument>(
  {
    userid: {
      type: String,
      required: true,
    },
    modelid: {
      type: mongoose.Schema.Types.ObjectId,
      ref: "Model",
    },
    modelIds: [{ type: mongoose.Schema.Types.ObjectId, ref: "Model" }],
    voiceTimes: [],
    jobid: {
      type: String,
    },
    originalUrl: {
      type: String,
      required: true,
    },
    convertedUrl: {
      type: String,
    },
    combinedUrl: {
      type: String,
    },
    instrumentalUrl: {
      type: String,
    },
    status: {
      type: String,
    },
    conversionStatus: {
      type: String,
    },
    conversionType: {
      type: String,
    },
    info: {
      acapella: {
        type: String,
        default: "waiting",
      },
      model: {
        type: String,
        default: "waiting",
      },
    },
    public: {
      type: Boolean,
      default: true,
    },
    shortid: {
      type: String,
      unique: true,
    },
    name: {
      type: String,
    },
    downloads: {
      type: Number,
      default: 0,
    },
    showcase: {
      type: Boolean,
      default: false,
    },
    youtubeUrl: {
      type: String,
    },
    ip: {
      type: String,
    },
    mode: {
      type: String,
    },
    failCount: {
      type: Number,
      default: 0,
    },
    toolMode: {
      isToolMode: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
      },
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
    schemaVersion: {
      type: Number,
      default: 1,
    },
    userPlanName: {
      type: String,
      required: false,
    },
    duration: {
      type: Number,
      required: false,
    },
    clip: {
      duration: {
        type: Number,
      },
      inTime: {
        type: Number,
      },
      outTime: {
        type: Number,
      },
    },
    librarySongId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: "Song",
    },
    settings: {
      addEffects: {
        type: Boolean,
      },
      extractBackground: {
        type: Boolean,
      },
      pitch: {
        type: Array,
      },
      highQuality: {
        type: Boolean,
      },
      levelDb: {
        type: Number,
      },
      skipExtraction: {
        type: Boolean,
      },
      swapRate: {
        type: Number,
      },
      autotune: {
        enabled: {
          type: Boolean,
        },
        strength: {
          type: Number,
        },
      },
    },
  },
  { timestamps: true },
);

ConversionSchema.index({ createdAt: 1 });
ConversionSchema.index({ modelid: 1 });

const ConversionModel: Model<ConversionDocument> =
  mongoose?.models?.Conversion ||
  mongoose.model<ConversionDocument>("Conversion", ConversionSchema);

export default ConversionModel;
