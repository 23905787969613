import React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "@jammable/ui-core";

type SwitchProps = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & {
  className?: string;
};

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    style={{
      height: 24,
      width: 45,
    }}
    className={cn(
      "tw-peer tw-m-0 tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-none tw-bg-transparent tw-p-0 tw-shadow-none tw-outline-none tw-transition-colors focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-background disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary data-[state=unchecked]:tw-bg-input",
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "tw-pointer-events-none tw-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition-transform  data-[state=checked]:tw-translate-x-6 data-[state=unchecked]:tw-translate-x-0.5",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;
