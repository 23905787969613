import { cn } from "@jammable/ui-core";
import * as React from "react";
import { Drawer as DrawerPrimitive } from "vaul";

export const Drawer = ({
  // eslint-disable-next-line react/prop-types -- comes like this in shadcnui:
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);

export const DrawerTrigger = DrawerPrimitive.Trigger;

export const DrawerPortal = DrawerPrimitive.Portal;

export const DrawerClose = DrawerPrimitive.Close;

export const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn("tw-fixed tw-inset-0 tw-z-[300] tw-bg-black/80", className)}
    {...props}
  />
));

DrawerOverlay.displayName = "DrawerOverlay";

export const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DrawerPortal>
    <DrawerOverlay />
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(
        "tw-fixed tw-inset-x-0 tw-bottom-0 tw-z-[400] tw-flex tw-h-auto tw-max-h-[96%] tw-flex-col tw-rounded-t-[10px] tw-border tw-bg-white tw-pb-5",
        className,
        "focus:tw-outline-none", // Add this line to remove focus outline
      )}
      {...props}
    >
      <div className="tw-mx-auto tw-mb-3 tw-mt-4 tw-h-1.5 tw-w-[60px] tw-rounded-full tw-bg-gray-200" />
      <div className="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-overflow-auto tw-rounded-t-[10px]">
        {children}
      </div>
    </DrawerPrimitive.Content>
  </DrawerPortal>
));

DrawerContent.displayName = "DrawerContent";

export const DrawerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "tw-grid tw-gap-1.5 tw-p-4 tw-text-center sm:tw-text-left",
      className,
    )}
    {...props}
  />
);

DrawerHeader.displayName = "DrawerHeader";

export const DrawerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
}) => (
  <div
    className={cn("tw-mt-auto tw-flex tw-flex-col tw-gap-2 tw-p-4", className)}
    {...props}
  />
);

DrawerFooter.displayName = "DrawerFooter";

export const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn(
      "tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight",
      className,
    )}
    {...props}
  />
));

DrawerTitle.displayName = "DrawerTitle";

export const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn("tw-text-sm", className)}
    {...props}
  />
));

DrawerDescription.displayName = "DrawerDescription";
