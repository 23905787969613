import React from "react";
import Header from "./header/Header";
import PageFooter from "./PageFooter";
import { useModal } from "../context/ModalContext";
import RebrandModal from "./modals/RebrandModal";
import { HiCursorClick } from "react-icons/hi";
import GlobalAudioPlayer from "./GlobalAudioPlayer";
import BottomNavbar from "./BottomNavbar";

const Layout = ({ children }) => {
  const { openModal, closeModal } = useModal();

  const handleKeyPress = (event) => {
    // Check if the key pressed is 'Enter' or 'Space'
    if (event.key === "Enter" || event.key === " ") {
      // Call the same function as the onClick event
      openModal(
        "rebrand-modal",
        <RebrandModal closeModal={() => closeModal("delete-comment-modal")} />,
      );
    }
  };

  return (
    <>
      <div>
        {/* <div
          role="button"
          tabIndex="0" // Make the div focusable
          onKeyDown={handleKeyPress}
          onClick={() => {
            openModal(
              "rebrand-modal",
              <RebrandModal closeModal={() => closeModal("rebrand-modal")} />,
            );
          }}
          className="tw-w-full tw-font-bold tw-text-white tw-h-7  tw-flex tw-items-center tw-justify-center tw-fixed tw-top-0 tw-bg-purple-700 tw-z-[100]"
        >
          Voicify AI is now Jammable!
          <HiCursorClick size={18} style={{ marginLeft: 4 }} />
        </div> */}
        <Header />
        <BottomNavbar />
      </div>
      <main className="tw-w-full   tw-scrollbar-thumb-gray-200  tw-scrollbar-thumb-rounded-xl  tw-scrollbar-track-gray-100 tw-scrollbar-thin tw-scrollbar-track-transparent">
        {children}
      </main>
      <PageFooter />
    </>
  );
};

export default Layout;
