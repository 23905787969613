import { getAuthHeaders } from "../shared";

export type Currency = "GBP" | "USD" | "EUR" | "INR";

export interface AvailableUpgrade {
  id: string;
  type: "creator" | "power" | "basic";
  title: string;
  catalogue?: "new_subscription";
  description?: Array<string>;
  amount: number;
  mostPopular: boolean;
  percentageDiscount?: number;
  discountedAmount?: number;
  currency?: Currency;
  conversions: string;
  models: string | number;
  product: {
    id: string;
    name: string;
    coupons?: {
      amountOff?: number;
      percentOff?: number;
    }[];
  };
  plan: { interval: "month" | "year" };
}

export interface UpgradeOptionsResponse {
  subscription?: {
    currency: Currency;
  };
  availableUpgrades: AvailableUpgrade[];
}

// @TODO should come from backend as metadata
// but for now centralising here in a mutator
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mutateResponse = (data: any) => {
  return {
    subscription: data.subscription,
    availableUpgrades: data.availableUpgrades
      .map((upgrade: AvailableUpgrade) => ({
        ...upgrade,
        mostPopular: upgrade.product.name === "Jammable Creator",
        type: {
          "Jammable Basic": "basic",
          "Jammable Creator": "creator",
          "Jammable Power User": "power",
        }[upgrade.product.name],
        title: {
          "Jammable Basic": "Starter",
          "Jammable Creator": "Creator",
          "Jammable Power User": "Power",
        }[upgrade.product.name],
        description: {
          "Jammable Basic": [
            "25 Monthly Credits",
            "Duets",
            "Text to Speech",
            "Full Voice Library",
          ],
          "Jammable Creator": [
            "Unlimited Credits",
            "3 Monthly Voices",
            "Text to Speech",
            "Premium Quality",
            "Full Voice Library",
          ],
          "Jammable Power User": [
            "Unlimited Credits",
            "10 Monthly Voices",
            "Premium Quality",
          ],
        }[upgrade.product.name],
        models: {
          "Jammable Creator": 3,
          "Jammable Power User": 10,
        }[upgrade.product.name],
        conversions: {
          "Jammable Basic": "25 per month",
          "Jammable Creator": "Unlimited",
          "Jammable Power User": "Unlimited",
        }[upgrade.product.name],
        ...(upgrade.product.coupons?.[0]?.amountOff
          ? {
              discountedAmount:
                upgrade.amount - upgrade.product.coupons?.[0]?.amountOff,
              percentageDiscount: Math.round(
                (upgrade.product.coupons?.[0]?.amountOff * 100) /
                  upgrade.amount,
              ),
            }
          : {}),
      }))
      .sort((a: AvailableUpgrade, b: AvailableUpgrade) => {
        // Calculate the final price considering discountedAmount if present
        const finalPriceA = a.discountedAmount ?? a.amount;
        const finalPriceB = b.discountedAmount ?? b.amount;
        return finalPriceA - finalPriceB;
      }),
  };
};

export async function fetchSubscriptionUpgradeOptions(
  interval = "month",
  catalogue = "new_subscription",
): Promise<UpgradeOptionsResponse> {
  const response = await fetch(`/api/payment/subscription`, {
    headers: { ...(await getAuthHeaders()) },
  });
  const data = await response.json();
  const mutated = mutateResponse(data);
  return {
    ...mutated,
    availableUpgrades: mutated.availableUpgrades.filter(
      (upgrade: AvailableUpgrade) => {
        return (
          upgrade.plan.interval === interval && upgrade.catalogue === catalogue
        );
      },
    ),
  };
}
