import { createContext, useContext, useEffect, useState } from "react";
import firebase_app from "../utils/firebase";
import { getAuth } from "firebase/auth";
import nookies from "nookies";
import axios from "axios";

const AuthContext = createContext();

const fetchUser = async (uid, token) => {
  return await axios
    .get(`/api/user`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(firebase_app);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (firebaseUser) => {
      setLoading(true);
      if (!firebaseUser) {
        setUser(null);
        nookies.set(undefined, "token", "", { path: "/" });
        localStorage.removeItem("user");
      } else {
        const token = await auth.currentUser.getIdToken();
        let userData = null;
        let attempts = 0;
        while (attempts < 8) {
          try {
            userData = await fetchUser(firebaseUser.uid, token);
            break;
          } catch (error) {
            console.error("Error fetching user data:", error);
            attempts++;
            if (attempts < 8) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          }
        }
        if (!userData) {
          console.error("Failed to fetch user data after 3 attempts");
          setLoading(false);
          return;
        }
        const updatedUser = { ...firebaseUser, ...userData };
        setUser(updatedUser);
        localStorage.setItem("user", JSON.stringify(updatedUser));
        nookies.set(undefined, "token", token, { path: "/" });
        sessionStorage.setItem("authToken", token);
      }
      setLoading(false);
    });

    // Initialize user state from localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    return () => unsubscribe();
  }, [auth]);

  // Token refresh logic
  useEffect(() => {
    const handle = setInterval(
      async () => {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken(true);
          nookies.set(undefined, "token", token, { path: "/" });
          sessionStorage.setItem("authToken", token);
        }
      },
      10 * 60 * 1000,
    ); // Corrected to 10 minutes

    return () => clearInterval(handle);
  }, [auth]);

  const updateUser = (updatedUserData) => {
    setUser((prevUser) => {
      const newUser = { ...prevUser, ...updatedUserData };
      localStorage.setItem("user", JSON.stringify(newUser));
      return newUser;
    });
  };

  return (
    <AuthContext.Provider value={{ user, setUser, updateUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
