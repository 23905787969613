import { getAuth } from "firebase/auth";

// use SITE_URL or VERCEL_URL server side, use empty clientside
export const BASE_URL = process.env.SITE_URL
  ? process.env.SITE_URL
  : process.env.VERCEL_URL
    ? `https://${process.env.VERCEL_URL}`
    : "";

export const getAuthHeaders = async (): Promise<HeadersInit> => {
  let token;
  try {
    const auth = getAuth();
    token = await auth.currentUser?.getIdToken();
  } catch (e) {
    if (sessionStorage?.hasItem?.("authToken")) {
      token = sessionStorage.getItem("authToken");
    }
  }

  if (!token) return {};

  return {
    Authorization: `${token}`,
  };
};

export const getAuthBearerHeaders = async (): Promise<HeadersInit> => {
  const auth = getAuth();
  const token = await auth?.currentUser?.getIdToken();

  if (!token) return {};

  return {
    Authorization: `Bearer ${token}`,
  };
};
