"use client";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { setCookie } from "nookies";
import { usePrevious } from "@uidotdev/usehooks";

if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://eu.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
    capture_pageview: false,
    capture_pageleave: false,
    autocapture: false,
  });
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  const auth: { user?: { uid: string; email: string } } = useAuth();

  const userId = auth.user?.uid;

  const prevUserId = usePrevious(userId);

  const distinctId = posthog.get_distinct_id();
  useEffect(() => {
    if (userId && distinctId !== userId) {
      posthog.identify(userId, { email: auth.user?.email });
    } else if (prevUserId && !userId) {
      posthog.reset();
    }
  }, [auth.user?.email, prevUserId, userId, distinctId]);

  useEffect(() => {
    setCookie(null, "distinctIdPostHog", distinctId);
  });

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
