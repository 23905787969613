// ...

import Image from "next/image";
import logo from "../../assets/logo.png";

const RebrandModal = () => {
  const subtitle = {
    fontWeight: 900,
  };
  return (
    <div style={{ gap: 5 }} className="vertical-flex-10">
      <div
        style={{
          fontWeight: 700,
          letterSpacing: 2,
          opacity: 0.4,
          fontSize: 14,
        }}
      >
        ANNOUNCEMENT
      </div>
      <Image
        style={{ position: "absolute", right: 12, top: 12 }}
        src={logo}
        width={30}
        height={30}
      />

      <div
        style={{
          fontWeight: 900,
          fontSize: 28,
          letterSpacing: -1,
        }}
      >
        Voicify AI is now Jammable!
      </div>

      <div style={subtitle}>Why are we rebranding?</div>
      <div>
        This rebrand signifies our move away from just being an{" "}
        <span style={{ fontStyle: "italic" }}>
          &apos;AI Voice Platform&apos;
        </span>
        , as we explore and offer new experiences for our users!
      </div>

      <div style={subtitle}>What will change?</div>
      <div>
        All your account information and history will remain & you will still be
        able to use the platform in the same way you know and love!
      </div>
      <div
        style={{
          fontWeight: 700,
          fontSize: 18,
          fontStyle: "italic",
        }}
      >
        {" "}
        The Jammable Team
      </div>
    </div>
  );
};

export default RebrandModal;
