import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  width: 45px;
  height: 44px;
`;

const Circle = styled.circle`
  stroke-width: 1.5;
`;

const Ellipse = () => {
  return (
    <Svg viewBox="0 0 45 44" fill="none">
      <Circle
        cx="22.5"
        cy="22"
        r="21.25"
        fill="url(#paint0_radial_2459_4033)"
        stroke="url(#paint1_radial_2459_4033)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2459_4033"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.5) rotate(90) scale(44)"
        >
          <stop offset="0.30639" stopColor="white" stopOpacity="0" />
          <stop offset="0.82" stopColor="#B52DC1" stopOpacity="0.5" />
          <stop offset="1" stopColor="#6F2DC1" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2459_4033"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.5) rotate(90) scale(44)"
        >
          <stop offset="0.511556" stopColor="white" stopOpacity="0" />
          <stop offset="0.82" stopColor="#B52DC1" stopOpacity="0.5" />
          <stop offset="1" stopColor="#6F2DC1" />
        </radialGradient>
      </defs>
    </Svg>
  );
};

export default Ellipse;
