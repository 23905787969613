import { DropdownMenuItem } from "@jammable/ui-components/DropdownMenu";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const DropDownLink = ({ href, title, description, icon, isNew }) => {
  const pathname = usePathname();
  const isExternalLink = href.startsWith("https");

  return (
    <DropdownMenuItem asChild className="tw-w-full tw-p-0">
      <Link
        className="tw-w-full"
        href={href}
        target={isExternalLink ? "_blank" : undefined}
        rel={isExternalLink ? "noopener noreferrer" : undefined}
      >
        <div
          className={`tw-font-bold tw-w-full tw-flex tw-items-center tw-gap-2 tw-cursor-pointer  ${pathname === href && "tw-bg-gray-100"} tw-rounded-2xl tw-transition-all hover:tw-bg-gray-100 tw-p-2  tw-px-3`}
        >
          {icon && icon}
          <div>
            {title}
            <div className="tw-text-sm tw-font-semibold tw-text-black/40">
              {description}{" "}
            </div>
          </div>
          {isNew && (
            <div className=" tw-rounded-full tw-bg-green-400 tw-text-white tw-font-black tw-flex tw-items-center tw-text-center tw-justify-center tw-text-xs tw-p-1 tw-px-2">
              NEW
            </div>
          )}
        </div>
      </Link>
    </DropdownMenuItem>
  );
};

export default DropDownLink;
