import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { cn, useMediaQuery } from "@jammable/ui-core";
import { FaTimes } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;

export const DialogPortal = DialogPrimitive.Portal;

export const DialogClose = DialogPrimitive.Close;

export const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "tw-fixed tw-inset-0 tw-z-[900] tw-bg-black/90",
      "data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0",
      className,
    )}
    {...props}
  />
));

DialogOverlay.displayName = "DialogOverlay";

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    closeIcon?: React.ReactNode;
    classNames?: { [key: string]: string };
    drawerMode?: boolean;
  }
>(
  (
    { className, classNames = {}, drawerMode, closeIcon, children, ...props },
    ref,
  ) => {
    const isDesktop = useMediaQuery("(min-width: 768px)");
    return (
      <DialogPortal>
        <DialogOverlay className={classNames["overlay"] || ""} />
        <div className="tw-fixed tw-inset-0 tw-z-[10000] tw-flex tw-flex-col  md:tw-items-center md:tw-justify-center">
          <DialogPrimitive.Content
            ref={ref}
            className={cn(
              "tw-relative tw-grid tw-gap-4 tw-border tw-bg-white tw-p-6 tw-shadow-lg",
              {
                " tw-max-w-4xl sm:tw-rounded-3xl md:tw-w-auto": isDesktop,
              },
              isDesktop
                ? "data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95"
                : drawerMode
                  ? "tw-bottom-0 tw-top-[initial] tw-mt-auto tw-w-full tw-translate-y-[0] tw-animate-slide-up tw-overflow-y-scroll"
                  : "tw-mt-auto tw-w-full ",
              className,
            )}
            {...props}
          >
            {children}
            <DialogPrimitive.Close
              className={cn(
                "tw-absolute tw-right-2.5 tw-top-2.5 tw-cursor-pointer tw-rounded-sm tw-border-0 tw-bg-transparent tw-p-0 tw-opacity-90 tw-transition-opacity hover:tw-opacity-100 disabled:tw-pointer-events-none",
                classNames["close"] || "",
              )}
            >
              {closeIcon ? (
                closeIcon
              ) : (
                <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-100">
                  <IoIosClose color="black" size={26} />{" "}
                </div>
              )}

              <span className="tw-sr-only">Close</span>
            </DialogPrimitive.Close>
          </DialogPrimitive.Content>
        </div>
      </DialogPortal>
    );
  },
);

DialogContent.displayName = "DialogContent";

export const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
}) => (
  <div
    className={cn(
      "tw-flex tw-flex-col tw-space-y-1.5 tw-text-center sm:tw-text-left",
      className,
    )}
    {...props}
  />
);

DialogHeader.displayName = "DialogHeader";

export const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
}) => (
  <div
    className={cn(
      "tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2",
      className,
    )}
    {...props}
  />
);

DialogFooter.displayName = "DialogFooter";

export const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight",
      className,
    )}
    {...props}
  />
));

DialogTitle.displayName = "DialogTitle";

export const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("tw-text-sm", className)}
    {...props}
  />
));

DialogDescription.displayName = "DialogDescription";
