import Stripe from "stripe";
import { z } from "zod";

const ZEnv = z.enum(["development", "production", "preview", "test"]);

export const ENV = ZEnv.parse(
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
    process.env.VERCEL_ENV ||
    process.env.NODE_ENV,
);

const isProduction = ENV === "production";

export const getPrice = (currency: string, tier: string) => {
  let price;

  if (tier === "basic") {
    price = 1.99;
  } else if (tier === "basic-discount") {
    price = 7.99;
  } else if (tier === "creator") {
    price = 9.99;
  } else if (tier === "creator-discount") {
    price = 24.99;
  } else if (tier === "power") {
    price = 89.99;
  } else {
    return null;
  }

  if (currency === "INR") {
    price = adjustPriceForINR(tier);
  }

  return price;
};

const adjustPriceForINR = (tier: string) => {
  const pricesInINR = {
    basic: 99,
    creator: 1249,
    "creator-discount": 1999,
    "basic-discount": 399,
    power: 4499,
  };

  return pricesInINR[tier as keyof typeof pricesInINR] || null;
};

export const earlyAdopterPlanId = [
  "price_1NfQC7CUwJnq3QQBvRg8h9mQ", //USD MONTHLY

  "price_1NfQC7CUwJnq3QQBbViXalpE", //GBP MONTHLY

  "price_1NfQC7CUwJnq3QQBHMHe6Bwn", // EUR MONTHLY

  "price_1Nxt9gCUwJnq3QQBEril38wT",

  "price_1Nxt9xCUwJnq3QQBsd3D4Uoh",

  "price_1NxtABCUwJnq3QQBPKzNh0j5",

  "price_1OnQ84CUwJnq3QQBiPXNJwSD",

  "price_1OnQ8rCUwJnq3QQBOqH7iYez",

  "price_1OnQ92CUwJnq3QQBnckhJOAZ",
];

export const basicPlanId = isProduction
  ? [
      "price_1NVMJTCUwJnq3QQBbxqgtALq", //USD MONTHLY

      "price_1NVMKmCUwJnq3QQBgJk67lD1", //GBP MONTHLY

      "price_1NVMKQCUwJnq3QQBWLra0VwZ", // EUR MONTHLY

      "price_1Nr1q2CUwJnq3QQBxrGQFU8s", //USD MONTHLY 7.99

      "price_1Nr1qBCUwJnq3QQBi6JNTOTa", //GBP MONTHLY 7.99

      "price_1Nr1qMCUwJnq3QQBU936IGwN", // EUR MONHTLY 7.99

      "price_1NxYHHCUwJnq3QQBe62v7xcL", // INR MONTHLY
    ]
  : [
      "price_1NVMOzCUwJnq3QQBV8JbGqKr", //USD MONTHLY

      "price_1OfJvbCUwJnq3QQBVP51y4MY", //GBP MONTHLY

      "price_1NVMKQCUwJnq3QQBWLra0VwZ", // EUR MONTHLY
      "",
      "price_1OfJvbCUwJnq3QQBVP51y4MY",
      "",
      "price_1NxYNkCUwJnq3QQBpdqyezhP",
    ];

export const creatorPlanId = isProduction
  ? [
      "price_1MwYlgCUwJnq3QQBaxn8t4Jf", //USD MONTHLY

      "price_1MwYlgCUwJnq3QQBqyd9oCiY", //USD YEARLY

      "price_1N7K8xCUwJnq3QQBHhm5Y4IU", //GBP MONTHLY

      "price_1N7K9RCUwJnq3QQBR8bgAacP", //GBP YEARLY

      "price_1N7K9xCUwJnq3QQBBiH8Uk5j", // EUR MONTHLY

      "price_1N7KALCUwJnq3QQBRKfVW6wb", // EUR YEARLY

      "price_1NxYHlCUwJnq3QQB0TTeBpXb", //INR MONTHLY

      "price_1PrOlFCUwJnq3QQBqbMfS8QE", // USD SECRET MONTHLY

      "price_1PrOlYCUwJnq3QQBk2JKgmc8", //GBP SECRET MONTHLY

      "price_1PrOlYCUwJnq3QQBESmEWpyh", //EUR SECRET MONTHLY
    ]
  : [
      "price_1MvQEuCUwJnq3QQBQSWoTJBd",
      "price_1NPooWCUwJnq3QQBEdYEIgdQ",
      "price_1N7K0CCUwJnq3QQBLc6IUJgV", //GBP MONTHLY
      "dd",
      "price_1N7K0CCUwJnq3QQBLc6IUJgV", //EUR MONTHLY
      "",
      "price_1NxYRQCUwJnq3QQBSkZHr7e9",
    ];

export const powerPlanId = isProduction
  ? [
      "price_1MwYn7CUwJnq3QQB6mg34Jf9", //usd monthky

      "price_1N7KEkCUwJnq3QQB3EmjwmBP", //gbp monthly

      "price_1N7KFDCUwJnq3QQBzn08BY8B", //eur monthly

      "price_1NxYI3CUwJnq3QQB8MXWWU1a", //INR MONTHLY
    ]
  : [
      "price_1MvQU5CUwJnq3QQBuho7gelx",
      "price_1N7KGaCUwJnq3QQBxVjM33ap",
      "price_1N7KGoCUwJnq3QQBO9d5HNTH",
      "price_1NxYRgCUwJnq3QQB69I92HJj",
    ];

interface Subscription {
  subscriptionId: string;
  planId: string;
  status: Stripe.Subscription.Status;
  periodEnd: Date;
}

export type PlanName = "free" | "basic" | "early adopter" | "creator" | "power";

export const getSubscriptionStatus = (
  subscriptions: Subscription | Subscription[],
): PlanName => {
  const plans = [subscriptions]
    .flat()
    .filter(Boolean) // Ensure subscriptions is always an array
    .filter((subscription) => subscription.status === "active")
    .flatMap((sub) => sub.planId);

  if (plans.some((planId) => powerPlanId.includes(planId))) {
    return "power";
  }

  if (plans.some((planId) => creatorPlanId.includes(planId))) {
    return "creator";
  }

  if (plans.some((planId) => basicPlanId.includes(planId))) {
    return "basic";
  }

  if (plans.some((planId) => earlyAdopterPlanId.includes(planId))) {
    return "early adopter";
  }

  return "free";
};

export const getSubscriptionStatusSettings = (
  subscriptions: Subscription | Subscription[],
): PlanName => {
  const plans = [subscriptions]
    .flat()
    .filter(Boolean) // Ensure subscriptions is always an array
    .filter(
      (subscription) =>
        subscription.status === "active" || subscription.status === "past_due",
    )
    .flatMap((sub) => sub.planId);

  if (plans.some((planId) => powerPlanId.includes(planId))) {
    return "power";
  }

  if (plans.some((planId) => creatorPlanId.includes(planId))) {
    return "creator";
  }

  if (plans.some((planId) => basicPlanId.includes(planId))) {
    return "basic";
  }

  if (plans.some((planId) => earlyAdopterPlanId.includes(planId))) {
    return "early adopter";
  }

  return "free";
};
