import { IoCheckmarkSharp, IoHeartSharp } from "react-icons/io5";
import { UpgradeOptionsResponse } from "@jammable/clientapi/src/payments";
import {
  RadioGroup,
  RadioGroupItem,
} from "@jammable/ui-core/src/primitives/RadioGroup";
import { Button, cn } from "@jammable/ui-core";
import { formatCurrency } from "../../utils";
import { FaCheck, FaHeart } from "react-icons/fa";
import { CreditCheckout } from "../../../../../../../apps/web/components/modals/CreditCheckoutModal";
import { Spinner } from "@jammable/ui-components/Loader";

export function PlanSelector({
  upgradeOptions,
  selectedPlanName,
  currency,
  handleSelectPlan,
  hasSidebar,
  handleNewSubscription,
  showCheckout,
  checkoutParams,
  isUpgrading,
  isLoadingPrices,
  message,
}: {
  selectedPlanName?: string;
  currency?: "GBP" | "USD" | "EUR" | "INR";
  upgradeOptions?: UpgradeOptionsResponse["availableUpgrades"];
  handleSelectPlan: (value: string) => void;
  hasSidebar?: boolean;
  showCheckout?: boolean;
  checkoutParams?: {};
  handleNewSubscription: () => void;
  isUpgrading?: boolean;
  isLoadingPrices?: boolean;
  message?: { message?: string; item?: string };
}) {
  return showCheckout && checkoutParams ? (
    <div className="tw-w-full tw-p-5">
      <h2 className="tw-m-0 tw-mb-4 tw-text-3xl tw-font-black">Checkout</h2>
      <div>
        <CreditCheckout newLayout {...checkoutParams} />
      </div>
    </div>
  ) : (
    <RadioGroup
      className={` tw-flex tw-w-full ${hasSidebar && "md:tw-w-2/3"} tw-flex-col tw-gap-3 tw-rounded-3xl tw-bg-gray-100/80 tw-p-5 `}
      defaultValue={upgradeOptions?.[0]?.product.name}
      onValueChange={handleSelectPlan}
    >
      <div>
        <div
          className={`tw-text-xl tw-font-black ${message?.message && "tw-hidden"} md:tw-flex`}
        >
          Choose Plan to Continue
        </div>
        <div className="tw-text-sm tw-font-semibold tw-text-black/40">
          Join thousands of creators on a Jammable plan!
        </div>
      </div>
      {upgradeOptions ? (
        upgradeOptions?.map(
          ({
            id,
            title,
            description,
            amount,
            mostPopular,
            percentageDiscount,
            discountedAmount,
            plan,
            product,
          }) =>
            title !== "Power" && (
              <div
                key={id}
                className={`${hasSidebar ? `md:tw-w-[470px]` : "tw-w-full "}`}
              >
                <div
                  key={id}
                  className={cn(
                    "tw-relative tw-m-0 tw-rounded-3xl   tw-bg-white tw-p-1 tw-duration-100 md:tw-min-w-96 ",
                    {
                      "tw-bg-purple-100  tw-text-black tw-scale-[1.01]":
                        selectedPlanName === product.name,
                    },
                  )}
                >
                  <RadioGroupItem
                    id={id}
                    value={product.name}
                    checked={selectedPlanName === product.name}
                    className={cn("tw-absolute tw-right-3 tw-top-3", {
                      "tw-border-white": selectedPlanName === product.name,
                    })}
                  />
                  <label
                    htmlFor={id}
                    className="tw-block tw-h-full tw-cursor-pointer tw-px-4 tw-py-3 tw-text-left"
                  >
                    <div className="tw-te tw-flex tw-font-black tw-mb-2 tw-text-black tw-items-center">
                      {title}
                      {percentageDiscount && (
                        <div
                          className={cn(
                            " tw-ml-1 tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-4xl tw-bg-purple-700 tw-px-1.5 tw-py-1",
                          )}
                        >
                          <div className="tw-text-xs tw-font-black tw-uppercase tw-text-white ">
                            {`-${percentageDiscount}%`}
                          </div>
                        </div>
                      )}
                      {mostPopular && (
                        <div
                          style={{
                            background:
                              "linear-gradient(45deg, rgba(140,55,213,0.95) 5%, rgba(242,79,225,1) 30%, rgba(255,0,0,0.9) 100%)",
                          }}
                          className={cn(
                            "tw-ml-1 tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-4xl tw-bg-gradient-to-l tw-from-pink-500 tw-to-purple-600 tw-px-1.5 tw-py-1",
                          )}
                        >
                          <div className="tw-flex tw-items-center tw-gap-1 tw-px-1 tw-text-xs tw-font-black tw-uppercase tw-text-white">
                            <FaHeart />
                            <span className="tw-hidden md:tw-flex">
                              {" "}
                              Most
                            </span>{" "}
                            Popular
                          </div>
                        </div>
                      )}
                    </div>
                    {discountedAmount ? (
                      <div>
                        <div>
                          <span className="tw-font-bold">
                            {currency && discountedAmount
                              ? formatCurrency(discountedAmount, currency)
                              : ""}{" "}
                          </span>
                          <span className="tw-font-semibold">First Month</span>
                        </div>
                        <div className="tw-font-semibold tw-line-through tw-opacity-40">
                          {`${formatCurrency(amount, currency!)} per ${plan.interval}.`}
                        </div>
                      </div>
                    ) : (
                      <div className="tw-font-semibold  ">
                        <span className="tw-font-bold">
                          {" "}
                          {`${formatCurrency(amount, currency!)}`}{" "}
                        </span>{" "}
                        {`per ${plan.interval}.`}
                      </div>
                    )}

                    {/* {description && (
                    <>
                      <p className="tw-m-0 tw-text-sm tw-tracking-tight tw-font-semibold tw-text-black tw-mt-2">
                        {description}
                      </p>
                    </>
                  )} */}
                    {/* {description && description?.map((desc) => (
                    return <div>wdw</div>
                  )} */}
                    <div className="tw-mt-1.5 tw-flex tw-flex-wrap tw-gap-2 tw-text-sm tw-font-semibold ">
                      {description?.map((desc) => {
                        return (
                          <div
                            key={desc}
                            className="tw-flex tw-items-center tw-gap-2 tw-text-nowrap"
                          >
                            <FaCheck color="782dc8" size={15} strokeWidth={3} />
                            {desc}
                          </div>
                        );
                      })}
                    </div>
                  </label>
                </div>
              </div>
            ),
        )
      ) : (
        <div className="">
          <div className="tw-mx-auto tw-mb-4 tw-flex tw-h-32 tw-w-full  tw-animate-pulse tw-flex-col tw-items-start tw-justify-evenly tw-rounded-2xl tw-border-2 tw-border-solid tw-border-gray-100 tw-bg-white  tw-p-4 tw-py-2 sm:tw-min-w-[454px]">
            <div className="tw-h-4 tw-w-48 tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
            <div className="tw-h-6 tw-w-full tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
          </div>
          <div className="tw-mx-auto tw-mb-4 tw-flex tw-h-32 tw-w-full  tw-animate-pulse tw-flex-col tw-items-start tw-justify-evenly tw-rounded-2xl tw-border-2 tw-border-solid tw-border-gray-100 tw-bg-white  tw-p-4 tw-py-2 sm:tw-min-w-[454px]">
            <div className="tw-h-4 tw-w-48 tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
            <div className="tw-h-6 tw-w-full tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
          </div>

          <span className="tw-sr-only">Loading...</span>
        </div>
      )}
      <Button
        style={{
          fontSize: 17,
        }}
        onClick={handleNewSubscription}
        className={`tw-rounded-full tw-h-14 `}
        // isUpgrade
        disabled={isUpgrading || isLoadingPrices}
      >
        {isUpgrading ? (
          <Spinner className="tw-border-white tw-border-3" size={4} />
        ) : (
          "Continue"
        )}
      </Button>
      <div className="tw-text-center tw-text-sm tw-font-bold">
        No commitment - cancel anytime!
      </div>
    </RadioGroup>
  );
}
