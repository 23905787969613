import Footer from "rc-footer";
import React from "react";
import "rc-footer/assets/index.css"; // import 'rc-footer/asssets/index.less'
import Image from "next/image";
import logo from "../assets/logo.png";
import { BASE_URL } from "../utils/tools";
import { useTheme } from "next-themes";
import { useRouter } from "next/router";
import Link from "next/link";

const PageFooter = () => {
  const { theme } = useTheme();
  const router = useRouter();
  const models = [
    { name: "Drizzy", url: "drake" },
    { name: "Juice", url: "juicewrld" },
    { name: "Kanye", url: "kanyewest" },
    { name: "Ariana", url: "arianagrande" },
    { name: "Travis", url: "travisscott" },
    { name: "Weeknd", url: "theweeknd" },
    { name: "XXXTentacion", url: "xxxtentacion" },
    { name: "LAROI", url: "thekidlaroi" },
    { name: "Michael", url: "michaeljackson" },
    { name: "Eminem", url: "eminem" },
    { name: "Taylor", url: "taylorswift" },
    { name: "Harry", url: "harrystyles" },
  ];
  const footerVisible = [
    "/",
    "/settings",
    "/help",
    "/privacy",
    "/terms",
    "/[model]",
    "/new/[model]",
    "/models",
    "/trainmodel",
    "/refunds",
    "/tiktokchallenge",
    "/tiktokchallenge/terms",
    "/myvoices/[tab]",
    "/community",
    "/custommodels",
    // "/custommodels2",
    "/affiliate/terms",
    "/tools",
    "/tools/[tool]",
    "/pricing",
    "/community",
    "/community/[id]",
    "/user/[id]",
    "/dmca-policy",
    "/presets/[effect]",
    // "/conversion/[conversionid]",
    "/shop",
    "/train",
    "/collection/[collectionid]",
    "/@/[id]",
  ];
  const footer = footerVisible.includes(router.pathname);
  return !footer ? null : (
    <div
      style={{
        marginTop: router.pathname === "/custommodels" ? 0 : 40,
      }}
    >
      <Footer
        theme="dark"
        linkComponent={Link}
        columns={[
          {
            LinkComponent: Link,
            icon: (
              <Image
                onClick={() => {
                  router.push("/");
                }}
                alt="Jammable Logo"
                width={22}
                height={22}
                src={logo}
              />
            ),
            title: "Jammable",
            url: `${BASE_URL}`,
            openExternal: true,
            items: [
              { title: "Home", url: `${BASE_URL}/` },
              // { title: "Community", url: `${BASE_URL}/community` },
              { title: "Settings", url: `${BASE_URL}/settings` },
            ],
          },
          {
            title: "Links",
            openExternal: true,
            items: [
              { title: "Pricing", url: `${BASE_URL}/pricing` },
              { title: "Login", url: `${BASE_URL}/login` },
              { title: "Sign Up", url: `${BASE_URL}/signup` },
              { title: "Forgot Password", url: `${BASE_URL}/forgotpassword` },
              { title: "History", url: `${BASE_URL}/history` },
            ],
          },
          {
            title: "Legal",
            openExternal: true,
            items: [
              { title: "Terms of Use", url: `${BASE_URL}/terms` },
              { title: "Privacy Policy", url: `${BASE_URL}/privacy` },
              { title: "DMCA Policy", url: `${BASE_URL}/dmca-policy` },
              { title: "Refund Policy", url: `${BASE_URL}/refunds` },

              //   { title: "Cookie Policy", url: `${BASE_URL}/cookies` },
            ],
          },
        ]}
        bottom="© Copyright 2024 - Voicify AI LTD"
      />
    </div>
  );
};

export default PageFooter;
