import { ApiError } from "next/dist/server/api-utils";
import HttpStatusCode from "http-status-codes";
import Conversion from "../models/Conversion";
import axios from "axios";
import { isProduction } from "./env";
import {
  Bird,
  Bone,
  Bug,
  Cat,
  Dog,
  Fish,
  Origami,
  PawPrint,
  Rabbit,
  Squirrel,
  Turtle,
} from "lucide-react";
import { render } from "@react-email/render";
import DefaultTemplate from "../../web/components/emailTemplates/DefaultTemplate";

export * from "@jammable/ui-payments-feature/src/utils/subscriptions";

/**
 *
 * Server Side:
 *   In production and during local development SITE_URL is set to
 *   localhost:3000 and jammable.com, respectively. Preview environments use VERCEL_URL
 *
 * Client Side:
 *  use window.location.origin (where VERCEL_URL is not available)
 * */
export const getBaseUrl = () => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }
  return process.env.SITE_URL ?? `https://${process.env.VERCEL_URL}`;
};

import React from "react";
import { SES } from "@aws-sdk/client-ses";

const animals = [
  Rabbit,
  Cat,
  Dog,
  Bird,
  Fish,
  Bug,
  PawPrint,
  Squirrel,
  Turtle,
  Bone,
  Origami,
];

const randomIndex = Math.floor(Math.random() * animals.length);
const SelectedAnimal = animals[randomIndex];

export const getRandomAnimal = (size, strokeWidth) => {
  return <SelectedAnimal size={size} strokeWidth={strokeWidth} />;
};
export const BASE_URL = getBaseUrl();

const keyStr =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const triplet = (e1, e2, e3) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (r, g, b) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

export const gpuApiUrl = "https://api.runpod.ai/v2/hkkuhk2x8jun8o";

export const multi = 1;

export const bucketUrl = "https://imagecdn.voicify.ai/";

const freeMode = false;
export const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};
export const checkDailyUsage = async (ip, userid, res) => {
  if (freeMode) {
    const dayAgo = new Date();
    dayAgo.setDate(dayAgo.getDate() - 1);

    const freeConversions = await Conversion.count({
      createdAt: {
        $gte: dayAgo,
      },
      $or: [{ ip: ip }, { userid: userid }],
    });
    if (freeConversions > 2 && isProduction) {
      throw new Error("You have reached your free daily limit");
    }
  } else {
    throw new Error("You are out of credits");
  }
};

// interface NotificationSettings {
//   email: {
//     comments: boolean;
//     reply: boolean;
//     likes: boolean;
//     follows: boolean;
//     message: boolean;
//     disabled: boolean;
//   };
// }

export const checkNotificationEnabled = (user, notificationType) => {
  return (
    user?.notifications?.email?.[notificationType] !== false &&
    !user?.notifications?.email?.disabled
  );
};

export function getExceptionStatus(exception) {
  return exception instanceof ApiError
    ? exception.statusCode
    : HttpStatusCode.InternalServerError;
}

export function getExceptionMessage(exception) {
  return isError(exception) ? exception.message : `Internal Server Error`;
}

export function getExceptionStack(exception) {
  return isError(exception) ? exception.stack : undefined;
}

export function isError(exception) {
  return exception instanceof Error;
}

export function withExceptionFilter(req, res) {
  return async function (handler) {
    try {
      await handler(req, res);
    } catch (exception) {
      console.log(exception);
      // Handle the exception and log the error context
      // ...
    }
  };
}

export const customGoogleErrorMessages = {
  "auth/popup-closed-by-user":
    "The popup window was closed before completing the sign-in process. Please try again.",
  "auth/popup-blocked":
    "The popup window was blocked by the browser. Please enable popups and try again.",
  "auth/operation-not-supported-in-this-environment":
    "This sign-in method is not supported in your current environment.",
  "auth/network-request-failed":
    "A network error occurred. Please check your internet connection and try again.",
  "auth/cancelled-popup-request":
    "Multiple sign-in requests were detected. Please try again.",
  "auth/timeout":
    "The sign-in process took too long. Please check your internet connection and try again.",
  "auth/account-exists-with-different-credential":
    "An account with this email address already exists. Please sign in with the correct provider.",
  "auth/invalid-credential":
    "The provided sign-in credential is invalid or expired. Please try again.",
  "auth/user-disabled":
    "Your account has been disabled. Please contact support for assistance.",
  "auth/user-not-found":
    "No account found with this email address. Please try again or sign up for a new account.",
  "auth/wrong-password":
    "The password provided is incorrect. Please try again.",
};

export const customSignInErrorMessages = {
  "auth/invalid-email":
    "The email address you entered is invalid. Please check and try again.",
  "auth/user-disabled":
    "Your account has been disabled. Please contact support for assistance.",
  "auth/user-not-found":
    "No account found with this email address. Please try again or sign up for a new account.",
  "auth/wrong-password":
    "The password you entered is incorrect. Please try again.",
  "auth/too-many-requests":
    "Too many unsuccessful login attempts. Please try again later.",
};

export const customSignUpMessages = {
  "auth/email-already-in-use":
    "This email address is already in use by another account. Please use a different email.",
  "auth/invalid-email":
    "The email address you entered is invalid. Please check and try again.",
  "auth/operation-not-allowed":
    "Creating new accounts is currently not allowed. Please try again later.",
  "auth/weak-password":
    "The password must be at least 6 characters long. Please try a stronger password.",
};

export const getRandomUsername = () => {
  const words = [
    "apple",
    "banana",
    "cherry",
    "date",
    "fig",
    "grape",
    "kiwi",
    "lemon",
    "mango",
    "orange",
    "papaya",
    "raspberry",
    "strawberry",
    "tangerine",
    "watermelon",
    "avocado",
    "blueberry",
    "coconut",
    "elderberry",
    "guava",
    "hazelnut",
    "iceberg",
    "jackfruit",
    "kumquat",
    "lychee",
    "nectarine",
    "olive",
    "peach",
    "pineapple",
    "plum",
    "pomegranate",
    "quince",
    "raisin",
    "salmon",
    "tamarind",
    "tomato",
    "ugli",
    "vanilla",
    "walnut",
    "yam",
    "zucchini",
    "acorn",
    "almond",
    "cashew",
    "chestnut",
    "oyster",
    "pecan",
    "pistachio",
    "prune",
    "seaweed",
    "sunflower",
  ];

  function getRandomWord(words) {
    return words[Math.floor(Math.random() * words.length)];
  }

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const word1 = getRandomWord(words);
  const word2 = getRandomWord(words);
  const number = getRandomNumber(1, 100000);

  return `${word1}${word2}${number}`;
};

export const getDisplayName = (model, t) => {
  let name = model?.artistname;
  if (name?.includes("Model")) {
    name = name.replace("Model", "");
  }
  if (name?.includes("AI")) {
    name = name.replace("AI", "");
  }

  return `AI ${name} Voice`;
};

export const getDisplayName2 = (model, t) => {
  let name = model?.artistname;
  if (name?.includes("Model")) {
    name = name.replace("Model", "");
  }
  if (name?.includes("AI")) {
    name = name.replace("AI", "");
  }
  return `${name}`;
};
export const getToolInfo = (tool) => {
  let toolInfo;
  if (tool === "acapella-extractor") {
    toolInfo = {
      name: "Acapella Extractor",
      secondName: "Acapella Extraction",
      moreInfo: "This tool allows you to extract acapella versions of songs.",
      original: tool,
    };
  } else if (tool === "reverb-remover") {
    toolInfo = {
      name: "Reverb Remover",
      secondName: "Reverb Removal",
      moreInfo: "This tool allows you to extract acapella versions of songs.",
      original: tool,
    };
  }
  return toolInfo;
};

export const capitalizeFirstLetter = (str) => {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const customPasswordRestErrorMessages = {
  "auth/invalid-email": "Please enter a valid email address.",
  "auth/user-not-found": "No account found with this email.",
  "auth/missing-android-pkg-name": "An Android package name is required.",
  "auth/missing-continue-uri": "A continue URL is required.",
  "auth/missing-ios-bundle-id": "An iOS Bundle ID is required.",
  "auth/unauthorized-continue-uri": "The provided continue URL is not allowed.",
  "auth/operation-not-allowed":
    "Password reset is not enabled for this project.",
};

// Use it in the catch block to display

export const customResetPasswordErrorMessages = {
  "auth/invalid-action-code":
    "The action code is invalid. Please check the link and try again.",
  "auth/expired-action-code":
    "The action code has expired. Please request a new password reset email.",
  "auth/user-disabled":
    "This user account has been disabled. Please contact support.",
  "auth/user-not-found": "No account found with this email.",
  "auth/weak-password": "The password must be at least 6 characters.",
  "auth/operation-not-allowed":
    "Password reset is not enabled for this project.",
};

export const modelVoices = [
  {
    name: "Male Singing 1",
    param: "male_singing_1",
    original_url: "https://imagecdn.voicify.ai/demos/originals/brandon3.wav",
  },
  {
    name: "Male Singing 2",
    param: "male_singing_2",
    original_url: "https://imagecdn.voicify.ai/demos/originals/james2.wav",
  },

  {
    name: "Female Singing 1",
    param: "female_singing_1",
    original_url: "https://imagecdn.voicify.ai/demos/originals/leslie1.wav",
  },
  {
    name: "Female Singing 2",
    param: "female_singing_2",
    original_url: "https://imagecdn.voicify.ai/demos/originals/natasha2.wav",
  },
];

export const getIpFromRequest = (req) => {
  return (
    (req.headers["x-forwarded-for"] || "").split(",").pop().trim() ||
    req.connection.remoteAddress ||
    req.socket.remoteAddress ||
    req.connection.socket.remoteAddress
  );
};

export const sendEmail = async ({
  recipientEmail,
  subject,
  preview,
  href,
  heading,
  body,
  buttonText,
}) => {
  const emailHtml = render(
    <DefaultTemplate
      preview={preview}
      href={href}
      heading={heading}
      body={body}
      buttonText={buttonText}
    />,
  );

  const ses = new SES({
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
    region: "us-east-1",
  });

  const params = {
    Source: '"Jammable Updates" <updates@jammable.com>',
    Destination: { ToAddresses: [recipientEmail] },
    Message: {
      Body: { Html: { Charset: "UTF-8", Data: emailHtml } },
      Subject: {
        Charset: "UTF-8",
        Data: subject,
      },
    },
  };

  await ses.sendEmail(params);
};

export const createTts = async (
  text,
  model,
  expression,
  speed,
  accent,
  gender = "male",
) => {
  const voiceMap = {
    male: {
      US: "ai3-en-US-Joshua",
      FR: "ai3-fr-FR-Henri",
      DE: "ai3-de-DE-Kasper",
      ES: "ai3-es-US-Alberto",
      KR: "ai3-ko-KR-InJoon",
      GB: "ai3-en-GB-Thomas",
      IT: "ai3-it-IT-Diego",
      AU: "ai3-William",
      JP: "ai3-ja-JP-Ren",
    },
    female: {
      US: "ai3-Aria",
      FR: "ai3-fr-FR-Claire",
      DE: "ai3-de-DE-Marlene",
      ES: "ai3-es-US-Paz",
      KR: "ai3-ko-KR-Sena",
      GB: "ai3-en-GB-Rose",
      IT: "ai3-it-IT-Isabella",
      AU: "ai3-en-AU-Maddison",
      JP: "ai3-ja-JP-Sakura",
    },
  };

  const speedMap = {
    Slowest: -50,
    Slow: -25,
    Default: 0,
    Fast: 25,
    Fastest: 50,
  };

  function getSpeed(s) {
    const speed = speedMap[s];
    if (speed) {
      return `${speed}`;
    } else {
      return "0";
    }
  }

  function getVoiceId(gender, country) {
    const id = voiceMap[gender] && voiceMap[gender][country];
    if (id) {
      return id;
    } else {
      return "ai3-en-US-Joshua";
    }
  }

  if (text.length > 2500) {
    return res
      .status(401)
      .json({ message: "Maximum 2500 characters allowed!" });
  }

  const apiUrl = "https://developer.voicemaker.in/voice/api";
  const token2 = "4c5fd7a0-0d48-11ee-819a-01bdedca74ff";

  function convertPauses(text) {
    return text.replace(
      /\s*\*(\d+)\s*Second\s*Pause\*\s*/gi,
      (match, duration) => {
        return ` <break time='${duration}s'/> `;
      },
    );
  }

  const requestData = {
    Engine: "neural",
    VoiceId: getVoiceId(gender, accent),
    LanguageCode: "en-US",
    Text: convertPauses(text),
    OutputFormat: "mp3",
    SampleRate: "48000",
    Effect:
      expression && expression !== "Default"
        ? expression.toLowerCase()
        : "conversational",
    MasterSpeed: `${getSpeed(speed)}`,
    MasterVolume: "0",
    MasterPitch: "0",
  };
  const response = await axios.post(apiUrl, requestData, {
    headers: {
      Authorization: `Bearer ${token2}`,
      "Content-Type": "application/json",
    },
  });
  return response.data.path;
};

export const artistList = [
  "the weeknd",
  "kanye west",
  "taylor swift",
  "drake",
  "lana del rey",
  "kendrick lamar",
  "ariana grande",
  "beyoncé",
  "tyler, the creator",
  "rihanna",
  "arctic monkeys",
  "olivia rodrigo",
  "sza",
  "mitski",
  "frank ocean",
  "radiohead",
  "travis scott",
  "billie eilish",
  "doja cat",
  "future",
  "lady gaga",
  "tame impala",
  "hozier",
  "steve lacy",
  "coldplay",
  "the neighbourhood",
  "the smiths",
  "tv girl",
  "childish gambino",
  "kali uchis",
  "nirvana",
  "paramore",
  "bruno mars",
  "dua lipa",
  "gorillaz",
  "djo",
  "harry styles",
  "mac demarco",
  "a$ap rocky",
  "metro boomin",
  "katy perry",
  "21 savage",
  "the beatles",
  "post malone",
  "sabrina carpenter",
  "j. cole",
  "queen",
  "eminem",
  "fleetwood mac",
  "playboi carti",
  "pinkpantheress",
  "deftones",
  "calvin harris",
  "lorde",
  "mac miller",
  "britney spears",
  "the strokes",
  "nicki minaj",
  "clairo",
  "linkin park",
  "miley cyrus",
  "¥$",
  "jay-z",
  "lil uzi vert",
  "michael jackson",
  "justin bieber",
  "maroon 5",
  "the cure",
  "artemas",
  "xxxtentacion",
  "cigarettes after sex",
  "the cranberries",
  "joji",
  "beabadoobee",
  "elton john",
  "mgmt",
  "charli xcx",
  "red hot chili peppers",
  "brent faiyaz",
  "twenty one pilots",
  "baby keem",
  "conan gray",
  "system of a down",
  "troye sivan",
  "dominic fike",
  "foo fighters",
  "green day",
  "adele",
  "my chemical romance",
  "panic! at the disco",
  "cage the elephant",
  "david guetta",
  "abba",
  "beach house",
  "eyedress",
  "girl in red",
  "the killers",
  "newjeans",
  "tate mcrae",
  "shakira",
  "imagine dragons",
  "phoebe bridgers",
  "kid cudi",
  "pink floyd",
  "alex g",
  "david bowie",
  "one direction",
  "fall out boy",
  "daft punk",
  "yeat",
  "daniel caesar",
  "justin timberlake",
  "don toliver",
  "camila cabello",
  "outkast",
  "weezer",
  "tears for fears",
  "billy joel",
  "lil yachty",
  "bôa",
  "men i trust",
  "black eyed peas",
  "ed sheeran",
  "wallows",
  "noah kahan",
  "le sserafim",
  "laufey",
  "the smashing pumpkins",
  "pitbull",
  "nelly furtado",
  "pixies",
  "juice wrld",
  "hotel ugly",
  "d4vd",
  "the rolling stones",
  "tory lanez",
  "lil wayne",
  "kesha",
  "bts",
  "the 1975",
  "mf doom",
  "faye webster",
  "marina",
  "usher",
  "metallica",
  "florence + the machine",
  "lil peep",
  "twice",
  "melanie martinez",
  "daryl hall & john oates",
  "avril lavigne",
  "madonna",
  "gunna",
  "grimes",
  "miguel",
  "chappell roan",
  "slipknot",
  "selena gomez",
  "muse",
  "vacations",
  "marshmello",
  "trippie redd",
  "tyla",
  "onerepublic",
  "bryson tiller",
  "sufjan stevens",
  "amy winehouse",
  "halsey",
  "suki waterhouse",
  "blink-182",
  "lil tecca",
  "mazzy star",
  "oasis",
  "frank sinatra",
  "50 cent",
  "sade",
  "thundercat",
  "bad bunny",
  "shawn mendes",
  "jung kook",
  "cocteau twins",
  "korn",
  "labrinth",
  "lil nas x",
  "blur",
  "the notorious b.i.g.",
  "chief keef",
  "sam smith",
  "bring me the horizon",
  "glass animals",
  "zayn",
  "led zeppelin",
  "summer walker",
  "sia",
  "the cardigans",
  "boygenius",
  "creedence clearwater revival",
  "lord huron",
  "illit",
  "benson boone",
  "5 seconds of summer",
  "young thug",
  "megan thee stallion",
  "foster the people",
  "cults",
  "matt maltese",
  "demi lovato",
  "jack harlow",
  "the walters",
  "black sabbath",
  "the police",
  "khalid",
  "chris brown",
  "jid",
  "strawberry guy",
  "bee gees",
  "flo milli",
  "richy mitch & the coal miners",
  "limp bizkit",
  "big thief",
  "gwen stefani",
  "aphex twin",
  "slowdive",
  "kate bush",
  "2pac",
  "willow",
  "lil baby",
  "the marías",
  "ac/dc",
  "$uicideboy$",
  "jungle",
  "kodak black",
  "red velvet",
  "vansire",
  "pierce the veil",
  "chase atlantic",
  "joey bada$$",
  "carly rae jepsen",
  "ken carson",
  "natasha bedingfield",
  "rex orange county",
  "depeche mode",
  "kings of leon",
  "blackpink",
  "tomorrow x together",
  "aespa",
  "duster",
  "partynextdoor",
  "the chainsmokers",
  "crystal castles",
  "reneé rapp",
  "talking heads",
  "the offspring",
  "vampire weekend",
  "mother mother",
  "guns n' roses",
  "pearl jam",
  "cardi b",
  "lucki",
  "elliott smith",
  "bon iver",
  "pinegrove",
  "lizzy mcalpine",
  "flo rida",
  "avicii",
  "mariah carey",
  "current joys",
  "g-eazy",
  "tove lo",
  "the white stripes",
  "rosalía",
  "måneskin",
  "alice in chains",
  "bill withers",
  "brockhampton",
  "rich amiri",
  "(g)i-dle",
  "fiona apple",
  "schoolboy q",
  "p!nk",
  "jeff buckley",
  "pop smoke",
  "madison beer",
  "dj khaled",
  "the kid laroi",
  "two door cinema club",
  "the clash",
  "migos",
  "major lazer",
  "evanescence",
  "empire of the sun",
  "ellie goulding",
  "adrianne lenker",
  "charlie puth",
  "bob marley & the wailers",
  "mark ronson",
  "the alchemist",
  "earth, wind & fire",
  "offset",
  "gracie abrams",
  "a boogie wit da hoodie",
  "declan mckenna",
  "zach bryan",
  "ive",
  "marvin gaye",
  "stevie wonder",
  "pharrell williams",
  "madvillain",
  "denzel curry",
  "4batz",
  "jordana",
  "yot club",
  "billy idol",
  "bon jovi",
  "the black keys",
  "nas",
  "the lumineers",
  "sophie ellis-bextor",
  "the beach boys",
  "stephen sanchez",
  "the mamas & the papas",
  "three days grace",
  "bakar",
  "a-ha",
  "enhypen",
  "the velvet underground",
  "alt-j",
  "omar apollo",
  "björk",
  "the doors",
  "snoop dogg",
  "blondie",
  "ice cube",
  "vance joy",
  "jpegmafia",
  "queens of the stone age",
  "alicia keys",
  "m83",
  "joy division",
  "ice spice",
  "surf curse",
  "soundgarden",
  "dj snake",
  "dr. dre",
  "timbaland",
  "no doubt",
  "tiësto",
  "the goo goo dolls",
  "salvia palth",
  "kylie minogue",
  "a boogie wit da hoodie",
  "declan mckenna",
  "zach bryan",
  "ive",
  "marvin gaye",
  "stevie wonder",
  "pharrell williams",
  "madvillain",
  "denzel curry",
  "4batz",
  "jordana",
  "yot club",
  "billy idol",
  "bon jovi",
  "the black keys",
  "nas",
  "the lumineers",
  "sophie ellis-bextor",
  "the beach boys",
  "stephen sanchez",
  "the mamas & the papas",
  "three days grace",
  "bakar",
  "a-ha",
  "enhypen",
  "the velvet underground",
  "alt-j",
  "omar apollo",
  "björk",
  "the doors",
  "snoop dogg",
  "blondie",
  "ice cube",
  "vance joy",
  "jpegmafia",
  "queens of the stone age",
  "alicia keys",
  "m83",
  "joy division",
  "ice spice",
  "surf curse",
  "soundgarden",
  "dj snake",
  "dr. dre",
  "timbaland",
  "no doubt",
  "tiësto",
  "the goo goo dolls",
  "salvia palth",
  "kylie minogue",
  "new order",
  "toto",
  "eagles",
  "rage against the machine",
  "beach bunny",
  "r.e.m.",
  "car seat headrest",
  "her's",
  "the last dinner party",
  "kelly clarkson",
  "ski mask the slump god",
  "gucci mane",
  "cafuné",
  "disclosure",
  "rammstein",
  "key glock",
  "sign crushes motorist",
  "azealia banks",
  "fred again..",
  "nle choppa",
  "aerosmith",
  "montell fish",
  "earl sweatshirt",
  "beach weather",
  "destiny's child",
  "gotye",
  "elvis presley",
  "caroline polachek",
  "jvke",
  "ethel cain",
  "dolly parton",
  "tom odell",
  "cavetown",
  "rae sremmurd",
  "fitz and the tantrums",
  "kiss of life",
  "destroy lonely",
  "my bloody valentine",
  "swedish house mafia",
  "franz ferdinand",
  "electric light orchestra",
  "aaron may",
  "pavement",
  "itzy",
  "stray kids",
  "jimi hendrix",
  "orion sun",
  "roar",
  "isaiah rashad",
  "raye",
  "father john misty",
  "nickelback",
  "jhené aiko",
  "yung lean",
  "victoria monét",
  "jason derulo",
  "skrillex",
  "big sean",
  "still woozy",
  "bob dylan",
  "mgk",
  "train",
  "george michael",
  "jennifer lopez",
  "grouplove",
  "dave",
  "nine inch nails",
  "jimmy eat world",
  "paul mccartney",
  "zara larsson",
  "isabel larosa",
  "polo g",
  "keane",
  "karol g",
  "ghost",
  "french montana",
  "sixpence none the richer",
  "lykke li",
  "a tribe called quest",
  "macklemore & ryan lewis",
  "cyndi lauper",
  "flume",
  "u2",
  "clean bandit",
  "m.i.a.",
  "weyes blood",
  "dire straits",
  "akon",
  "seventeen",
  "panchiko",
  "sum 41",
  "ms. lauryn hill",
  "sean paul",
  "a$ap ferg",
  "wiz khalifa",
  "machine girl",
  "blood orange",
  "ricky montgomery",
  "whitney houston",
  "ne-yo",
  "odetari",
  "kim petras",
  "zedd",
  "kygo",
  "simon & garfunkel",
  "modest mouse",
  "arijit singh",
  "hans zimmer",
  "noah cyrus",
  "title fight",
  "lamp",
  "v",
  "aminé",
  "j balvin",
  "metric",
  "rina sawayama",
  "logic",
  "lauv",
  "christina aguilera",
  "bastille",
  "kehlani",
  "rainbow kitten surprise",
  "aurora",
  "teddy swims",
  "john lennon",
  "fergie",
  "duran duran",
  "mike posner",
  "iu",
  "selena gomez & the scene",
  "tlc",
  "tay-k",
  "little mix",
  "daddy yankee",
  "anitta",
  "kacey musgraves",
  "arcade fire",
  "dayglow",
  "beastie boys",
  "j-hope",
  "sonder",
  "nmixx",
  "james arthur",
  "jessie j",
  "bladee",
  "yeah yeah yeahs",
  "hole",
  "exo",
  "vundabar",
  "giveon",
  "sublime",
  "avenged sevenfold",
  "beck",
  "neutral milk hotel",
  "stayc",
  "khruangbin",
  "all time low",
  "kenya grace",
  "fifty fifty",
  "blackbear",
  "mavi",
  "goldlink",
  "sonic youth",
  "neon trees",
  "lynyrd skynyrd",
  "bizarrap",
  "prince",
  "the backseat lovers",
  "kiss",
  "lewis capaldi",
  "alec benjamin",
  "lily allen",
  "niall horan",
  "johnny cash",
  "vince staples",
  "young nudy",
  "journey",
  "100 gecs",
  "milky chance",
  "meek mill",
  "diplo",
  "freddie gibbs",
  "thee sacred souls",
  "david kushner",
  "the script",
  "league of legends",
  "bruce springsteen",
  "icona pop",
  "bibi",
  "steely dan",
  "young the giant",
  "westside gunn",
  "alvvays",
  "meghan trainor",
  "nct 127",
  "patrick watson",
  "xavier wulf",
  "wham!",
  "gym class heroes",
  "børns",
  "disturbed",
  "jorja smith",
  "alex_g_offline",
  "lil durk",
  "roddy ricch",
  "cochise",
  "nelly",
  "the kinks",
  "tally hall",
  "george harrison",
  "crumb",
  "falling in reverse",
  "the living tombstone",
  "gesaffelstein",
  "sexyy red",
  "john mayer",
  "kaytranada",
  "martin garrix",
  "wave to earth",
  "c418",
  "pet shop boys",
  "lizzo",
  "eve",
  "fetty wap",
  "dr. dog",
  "death grips",
  "erykah badu",
  "liana flores",
  "incubus",
  "justice",
  "ava max",
  "amaarae",
  "papa roach",
  "lay bankz",
  "japanese breakfast",
  "b.o.b",
  "sean kingston",
  "lucy dacus",
  "dababy",
  "nct dream",
  "the kooks",
  "dreamville",
  "quavo",
  "loona",
  "toby fox",
  "portishead",
  "ozzy osbourne",
  "phoenix",
  "the pussycat dolls",
  "girls' generation",
  "of monsters and men",
  "bigxthaplug",
  "youngboy never broke again",
  "thirty seconds to mars",
  "the game",
  "the all-american rejects",
  "don omar",
  "the drums",
  "estelle",
  "wisp",
  "joy again",
  "iron maiden",
  "alan walker",
  "yves tumor",
  "jonas brothers",
  "the fray",
  "nf",
  "keshi",
  "pusha t",
  "fka twigs",
  "øneheart",
  "men at work",
  "ashe",
  "agust d",
  "nav",
  "novo amor",
  "dove cameron",
  "ateez",
  "slayer",
  "bo burnham",
  "lisa",
  "basement",
  "bones",
  "sir chloe",
  "van morrison",
  "peso pluma",
  "kids see ghosts",
  "rob zombie",
  "backstreet boys",
  "slow pulp",
  "ashnikko",
  "mc saci",
  "fun.",
  "america",
  "max",
  "chance peña",
  "eurythmics",
  "king von",
  "freddie dredd",
  "galantis",
  "yoasobi",
  "alok",
  "loathe",
  "owl city",
  "phil collins",
  "chance the rapper",
  "tinashe",
  "etta james",
  "fleet foxes",
  "jamiroquai",
  "dream, ivory",
  "soft cell",
  "free nationals",
  "steven universe",
  "masego",
  "modern baseball",
  "walk the moon",
  "tool",
  "oliver tree",
  "the who",
  "peach pit",
  "interpol",
  "fugazi",
  "the national",
  "mareux",
  "pabllo vittar",
  "cuco",
  "remi wolf",
  "blue öyster cult",
  "nick drake",
  "bbno$",
  "megadeth",
  "bad omens",
  "macabre plaza",
  "jim croce",
  "solange",
  "king geedorah",
  "nina simone",
  "rauw alejandro",
  "van halen",
  "superheaven",
  "neil young",
  "fifth harmony",
  "pantera",
  "sleeping with sirens",
  "ramones",
  "wu-tang clan",
  "massive attack",
  "keyshia cole",
  "jeremih",
  "santana",
  "capital cities",
  "chung ha",
  "heart",
  "maggie rogers",
  "soulja boy",
  "jay rock",
  "chris isaak",
  "benny blanco",
  "h.e.r.",
  "central cee",
  "dusty springfield",
  "luísa sonza",
  "have a nice life",
  "juju<3",
  "morgan wallen",
  "lil tjay",
  "dpr ian",
  "air",
  "bleachers",
  "iggy azalea",
  "jimin",
  "thxsomch",
  "rise against",
  "ynw melly",
  "babymonster",
  "mumford & sons",
  "the japanese house",
  "mike",
  "russ",
  "fugees",
  "the sundays",
  "anderson .paak",
  "the paper kites",
  "stromae",
  "manchester orchestra",
  "ayesha erotica",
  "lenny kravitz",
  "taio cruz",
  "kankan",
  "boldy james",
  "jack johnson",
  "ryan gosling",
  "syml",
  "siouxsie and the banshees",
  "will.i.am",
  "wonder girls",
  "scorpions",
  "gregory alan isakov",
  "mr.kitty",
  "spice girls",
  "the internet",
  "ruth b.",
  "i dont know how but they found me",
  "nujabes",
  "supertramp",
  "portugal. the man",
  "enrique iglesias",
  "molchat doma",
  "cassö",
  "ekkstacy",
  "fog lake",
  "phantogram",
  "nothing but thieves",
  "charlie brown jr.",
  "flyleaf",
  "elley duhé",
  "good neighbours",
  "umi",
  "little simz",
  "shinee",
  "wolf alice",
  "le tigre",
  "feid",
  "type o negative",
  "becky g",
  "boards of canada",
  "shelly",
  "pity party (girls club)",
  "jaden",
  "kxllswxtch",
  "xg",
  "kero kero bonito",
  "ciara",
  "death cab for cutie",
  "niki",
  "jorge ben jor",
  "mindless self indulgence",
  "joe hisaishi",
  "ty dolla $ign",
  "the front bottoms",
  "poppy",
  "king krule",
  "jackboys",
  "doechii",
  "cascada",
  "the shins",
  "dnce",
  "viviz",
  "jawny",
  "the ronettes",
  "bonnie tyler",
  "the xx",
  "far east movement",
  "6arelyhuman",
  "leith ross",
  "tyga",
  "bazzi",
  "aretha franklin",
  "misfits",
  "the animals",
  "metronomy",
  "rio romeo",
  "american football",
  "yg marley",
  "paperboy fabe",
  "justin hurwitz",
  "frédéric chopin",
  "sir",
  "the rare occasions",
  "tracy chapman",
  "modjo",
  "claude debussy",
  "bryan adams",
  "3 doors down",
  "ajr",
  "a day to remember",
  "nayeon",
  "peggy gou",
  "king gizzard & the lizard wizard",
  "boy pablo",
  "wheatus",
  "alek olsen",
  "simple plan",
  "john legend",
  "stone temple pilots",
  "lumi athena",
  "alessia cara",
  "kaleo",
  "mötley crüe",
  "frankie cosmos",
  "michael marcagi",
  "t.a.t.u.",
  "chet baker",
  "porter robinson",
  "boney m.",
  "otis redding",
  "luke combs",
  "pastel ghost",
  "ichiko aoba",
  "the verve",
  "jack stauber's micropop",
  "james blake",
  "snow patrol",
  "sleep token",
  "breaking benjamin",
  "fromis_9",
  "aaliyah",
  "kansas",
  "ghostemane",
  "broadcast",
  "skillet",
  "saint motel",
  "6lack",
  "glorilla",
  "smash mouth",
  "grouper",
  "pyotr ilyich tchaikovsky",
  "dasha",
  "ecco2k",
  "royel otis",
  "taemin",
  "lcd soundsystem",
  "the jackson 5",
  "kai",
  "tim maia",
  "$not",
  "sleepy hallow",
  "smino",
  "sweet trip",
  "george ezra",
  "the la's",
  "marilyn manson",
  "johann sebastian bach",
  "alice phoebe lou",
  "the garden",
  "rita lee",
  "kanii",
  "mikeeysmind",
  "black country, new road",
  "tainy",
  "seafret",
  "t-pain",
  "taeyeon",
  "audioslave",
  "michael cera",
  "kenshi yonezu",
  "24kgoldn",
  "babytron",
  "eslabon armado",
  "moby",
  "jason mraz",
  "k/da",
  "whatever, dad",
  "mary j. blige",
  "djavan",
  "bloc party",
  "rm",
  "hybs",
  "bebe rexha",
  "novulent",
  "coin",
  "miki matsubara",
  "take care",
  "counting crows",
  "stan getz",
  "blue smiley",
  "al green",
  "homixide gang",
  "cream",
  "adventure time",
  "muni long",
  "haim",
  "pi’erre bourne",
  "erik satie",
  "chris stapleton",
  "fujii kaze",
  "new west",
  "joni mitchell",
  "glee cast",
  "motionless in white",
  "corinne bailey rae",
  "yusuf / cat stevens",
  "baco exu do blues",
  "tyler childers",
  "internet money",
  "louis armstrong",
  "olly alexander (years & years)",
  "dean",
  "whirr",
  "meduza",
  "paul simon",
  "between friends",
  "alanis morissette",
  "danny brown",
  "leehi",
  "suicidal-idol",
  "chico buarque",
  "ado",
  "ludmilla",
  "lesley gore",
  "michael bublé",
  "88rising",
  "judas priest",
  "mobb deep",
  "simple minds",
  "paris paloma",
  "elis regina",
  "ray charles",
  "kordhell",
  "placebo",
  "miracle musical",
  "magic!",
  "lil skies",
  "mild high club",
  "stevie nicks",
  "leslie odom jr.",
  "tom petty",
  "turnstile",
  "frankie valli",
  "ludwig van beethoven",
  "the prodigy",
  "lost frequencies",
  "a$ap mob",
  "mt. joy",
  "noturgf",
  "the zombies",
  "good charlotte",
  "badbadnotgood",
  "lou reed",
  "dean blunt",
  "creepy nuts",
  "del water gap",
  "day6",
  "the temptations",
  "dexys midnight runners",
  "sunmi",
  "riize",
  "coco & clair clair",
  "lyrical lemonade",
  "floyymenor",
  "shygirl",
  "mika",
  "the outfield",
  "she wants revenge",
  "nct u",
  "muna",
  "aliyah's interlude",
  "the wombats",
  "sleeping at last",
  "maluma",
  "jack stauber",
  "dennis",
  "everglow",
  "gfriend",
  "boston",
  "saweetie",
  "jeremy zucker",
  "6ix9ine",
  "pj harvey",
  "no vacation",
  "nat king cole",
  "shaggy",
  "manu chao",
  "hailee steinfeld",
  "gal costa",
  "mkto",
  "twista",
  "set it off",
  "mustard",
  "3oh!3",
  "yung gravy",
  "iron & wine",
  "yungblud",
  "gnarls barkley",
  "haley heynderickx",
  "tee grizzley",
  "lemon demon",
  "bruno major",
  "kodaline",
  "julie",
  "ozuna",
  "nick cave & the bad seeds",
  "kevin abstract",
  "ella fitzgerald",
  "inxs",
  "rick ross",
  "hoobastank",
  "third eye blind",
  "coolio",
  "eric clapton",
  "lxngvx",
  "magdalena bay",
  "plain white t's",
  "catfish and the bottlemen",
  "christina perri",
  "finneas",
  "edward maya",
  "allie x",
  "kavinsky",
  "sam cooke",
  "skepta",
  "two feet",
  "mason",
  "julia michaels",
  "marina sena",
  "modern talking",
  "st. vincent",
  "lmfao",
  "nemzzz",
  "billie holiday",
  "saba",
  "frou frou",
  "sophie",
  "autumn!",
  "grover washington, jr.",
  "dj lc",
  "bullet for my valentine",
  "joan jett & the blackhearts",
  "seether",
  "destroy boys",
  "j dilla",
  "mamamoo",
  "wolfgang amadeus mozart",
  "carrie underwood",
  "arca",
  "dean lewis",
  "dangerdoom",
  "the human league",
  "peter bjorn and john",
  "mura masa",
  "*nsync",
  "rema",
  "john denver",
  "clams casino",
  "sales",
  "summrs",
  "riton",
  "cher",
  "the wanted",
  "plan b",
  "kyle",
  "eiafuawn",
  "2 chainz",
  "cassie",
  "iz*one",
  "ralph castelli",
  "lil darkie",
  "the avalanches",
  "wayv",
  "babymetal",
  "armin van buuren",
  "mxmtoon",
  "nick jonas",
  "good kid",
  "1nonly",
  "caetano veloso",
  "foreigner",
  "joey valence & brae",
  "quality control",
  "vierre cloud",
  "carly simon",
  "chuck berry",
  "slayyyter",
  "deep purple",
  "dandelion hands",
  "zeph",
  "myke towers",
  "ludovico einaudi",
  "antonio vivaldi",
  "psy",
  "passenger",
  "kool & the gang",
  "corpse",
  "lil mosey",
  "stealers wheel",
  "bridgit mendler",
  "piero piccioni",
  "stereolab",
  "alesso",
  "dean martin",
  "lupe fiasco",
  "sting",
  "moneybagg yo",
  "violent vira",
  "home",
  "robbie williams",
  "rush",
  "ween",
  "foals",
  "robin schulz",
  "pulp",
  "gojira",
  "jão",
  "nbsplv",
  "creed",
  "jennie",
  "wet leg",
  "legião urbana",
  "la roux",
  "miles davis",
  "nessa barrett",
  "memo boy",
  "king crimson",
  "flying lotus",
  "regina spektor",
  "birdy",
  "jihyo",
  "belle and sebastian",
  "janet jackson",
  "deadmau5",
  "daughter",
  "shania twain",
  "royal blood",
  "baby smoove",
  "the last shadow puppets",
  "ludwig göransson",
  "nardo wick",
  "hollywood undead",
  "mom jeans.",
  "waka flocka flame",
  "norah jones",
  "britney manson",
  "brandy",
  "cake",
  "the temper trap",
  "n.w.a.",
  "violent femmes",
  "nancy sinatra",
  "jeon somi",
  "zz top",
  "lucidbeatz",
  "p1harmony",
  "flo",
  "odd future",
  "bronski beat",
  "k.flay",
  "yehezkel raz",
  "lancey foux",
  "brian eno",
  "don mclean",
  "steve miller band",
  "dreamcatcher",
  "maisie peters",
  "grentperez",
  "big red machine",
  "cazuza",
  "five finger death punch",
  "other nothing",
  "the stone roses",
  "anri",
  "motörhead",
  "fletcher",
  "margo guryan",
  "wings",
  "taking back sunday",
  "the cars",
  "unknown mortal orchestra",
  "bauhaus",
  "jin",
  "jefferson airplane",
  "the fratellis",
  "camille saint-saëns",
  "lyn lapid",
  "greta van fleet",
  "waterparks",
  "leonard cohen",
  "natalia lafourcade",
  "aoa",
  "idk",
  "snoh aalegra",
  "fuerza regida",
  "wilco",
  "drowning pool",
  "yo la tengo",
  "rascal flatts",
  "shy smith",
  "yes",
  "idles",
  "got7",
  "labi siffre",
  "ace of base",
  "céline dion",
  "penelope scott",
  "fleshwater",
  "afi",
  "tems",
  "anavitória",
  "illenium",
  "benee",
  "bea miller",
  "akiaura",
  "the vamps",
  "sheck wes",
  "gilsons",
  "triples",
  "rico nasty",
  "cordae",
  "tom rosenthal",
  "loving",
  "jessie ware",
  "x ambassadors",
  "cartola",
  "taeyang",
  "powfu",
  "my head is empty",
  "grizzly bear",
  "t.i.",
  "baekhyun",
  "berlioz",
  "bobby caldwell",
  "a perfect circle",
  "cypress hill",
  "strfkr",
  "redveil",
  "yellowcard",
  "anne-marie",
  "the red jumpsuit apparatus",
  "jonas blue",
  "alexandra stan",
  "bad gyal",
  "passion pit",
  "indigo de souza",
  "beach fossils",
  "iann dior",
  "the anxiety",
  "the pharcyde",
  "fontaines d.c.",
  "buffalo springfield",
  "l7nnon",
  "jakob",
  "echo & the bunnymen",
  "janelle monáe",
  "dazey and the scouts",
  "joyner lucas",
  "angel olsen",
  "seulgi",
  "toquinho",
  "arlo parks",
  "dhruv",
  "the b-52's",
  "genesis",
  "ben e. king",
  "scissor sisters",
  "roy orbison",
  "soda stereo",
  "wasia project",
  "glen campbell",
  "jordan ward",
  "briston maroney",
  "kt tunstall",
  "julia jacklin",
  "angus & julia stone",
  "burna boy",
  "spiritbox",
  "escape the fate",
  "iyaz",
  "trey songz",
  "finger eleven",
  "indila",
  "josé gonzález",
  "el alfa",
  "i prevail",
  "colde",
  "radwimps",
  "pedro sampaio",
  "the growlers",
  "kenny loggins",
  "tenacious d",
  "neil frances",
  "dido",
  "ari abdul",
  "supergrass",
  "jax jones",
  "jay sean",
  "gerry rafferty",
  "flipturn",
  "quinnie",
  "cortex",
  "quannnic",
  "comethazine",
  "sofaygo",
  "asking alexandria",
  "thom yorke",
  "jet",
  "the doobie brothers",
  "robyn",
  "odesza",
  "soccer mommy",
  "cutting crew",
  "luci4",
  "matuê",
  "cleo sol",
  "kikuo",
  "the daughters of eve",
  "dmx",
  "vegyn",
  "the psychedelic furs",
  "the chemical brothers",
  "knucks",
  "ybn nahmir",
  "polyphia",
  "three 6 mafia",
  "jalen ngonda",
  "the used",
  "blonde redhead",
  "survivor",
  "hiatus kaiyote",
  "lil b",
  "night lovell",
  "glass beach",
  "ramirez",
  "fisher",
  "parcels",
  "redbone",
  "glaive",
  "wabie",
  "rod stewart",
  "sky ferreira",
  "nct",
  "florist",
  "com truise",
  "bob sinclar",
  "king gnu",
  "andrew underberg",
  "lin-manuel miranda",
  "joyce manor",
  "dom corleo",
  "steve aoki",
  "good morning",
  "xxanteria",
  "olly murs",
  "c. tangana",
  "crush",
  "dimitri vegas & like mike",
  "carpenters",
  "interworld",
  "sega sound team",
  "diddy",
  "shinedown",
  "austin farwell",
  "haarper",
  "yaya bey",
  "chloe x halle",
  "nico",
  "architects",
  "marília mendonça",
  "dj shadow",
  "tom petty and the heartbreakers",
  "lonely god",
  "i monster",
  "taeyong",
  "pineapple stormtv",
  "the jesus and mary chain",
  "the magnetic fields",
  "kaytraminé",
  "mark ambor",
  "kurt cobain",
  "seu jorge",
  "garbage",
  "the oh hellos",
  "sidewalks and skeletons",
  "romeo santos",
  "gloria gaynor",
  "young stoner life",
  "the boyz",
  "busta rhymes",
  "bk",
  "bone thugs-n-harmony",
  "djonga",
  "jhayco",
  "alina baraz",
  "the turtles",
  "yg",
  "sam fender",
  "monsta x",
  "hippo campus",
  "sub urban",
  "stormzy",
  "toro y moi",
  "chevelle",
  "sara bareilles",
  "chvrches",
  "rod wave",
  "bright eyes",
  "olivia newton-john",
  "iggy pop",
  "hannah montana",
  "lebanon hanover",
  "natalie imbruglia",
  "ricky martin",
  "john williams",
  "hako",
  "aventura",
  "mannequin pussy",
  "toploader",
  "skank",
  "takayoshi",
  "crowded house",
  "the flaming lips",
  "late night drive home",
  "jack stauber",
  "dennis",
  "everglow",
  "gfriend",
  "boston",
  "saweetie",
  "jeremy zucker",
  "6ix9ine",
  "pj harvey",
  "no vacation",
  "nat king cole",
  "shaggy",
  "manu chao",
  "hailee steinfeld",
  "gal costa",
  "mkto",
  "twista",
  "set it off",
  "mustard",
  "3oh!3",
  "yung gravy",
  "iron & wine",
  "yungblud",
  "gnarls barkley",
  "haley heynderickx",
  "tee grizzley",
  "lemon demon",
  "bruno major",
  "kodaline",
  "julie",
  "ozuna",
  "nick cave & the bad seeds",
  "kevin abstract",
  "ella fitzgerald",
  "inxs",
  "rick ross",
  "hoobastank",
  "third eye blind",
  "coolio",
  "eric clapton",
  "lxngvx",
  "magdalena bay",
  "plain white t's",
  "catfish and the bottlemen",
  "christina perri",
  "finneas",
  "edward maya",
  "allie x",
  "kavinsky",
  "sam cooke",
  "skepta",
  "two feet",
  "mason",
  "julia michaels",
  "marina sena",
  "modern talking",
  "st. vincent",
  "lmfao",
  "nemzzz",
  "billie holiday",
  "saba",
  "frou frou",
  "sophie",
  "autumn!",
  "grover washington, jr.",
  "dj lc",
  "bullet for my valentine",
  "joan jett & the blackhearts",
  "seether",
  "destroy boys",
  "j dilla",
  "mamamoo",
  "wolfgang amadeus mozart",
  "carrie underwood",
  "arca",
  "dean lewis",
  "dangerdoom",
  "the human league",
  "peter bjorn and john",
  "mura masa",
  "*nsync",
  "rema",
  "john denver",
  "clams casino",
  "sales",
  "summrs",
  "riton",
  "cher",
  "the wanted",
  "plan b",
  "kyle",
  "eiafuawn",
  "2 chainz",
  "cassie",
  "iz*one",
  "ralph castelli",
  "lil darkie",
  "the avalanches",
  "wayv",
  "babymetal",
  "armin van buuren",
  "mxmtoon",
  "nick jonas",
  "good kid",
  "1nonly",
  "caetano veloso",
  "foreigner",
  "joey valence & brae",
  "quality control",
  "vierre cloud",
  "carly simon",
  "chuck berry",
  "slayyyter",
  "deep purple",
  "dandelion hands",
  "zeph",
  "myke towers",
  "ludovico einaudi",
  "antonio vivaldi",
  "psy",
  "passenger",
  "kool & the gang",
  "corpse",
  "lil mosey",
  "stealers wheel",
  "bridgit mendler",
  "piero piccioni",
  "stereolab",
  "alesso",
  "dean martin",
  "lupe fiasco",
  "sting",
  "moneybagg yo",
  "violent vira",
  "home",
  "robbie williams",
  "rush",
  "ween",
  "foals",
  "robin schulz",
  "pulp",
  "gojira",
  "jão",
  "nbsplv",
  "creed",
  "jennie",
  "wet leg",
  "legião urbana",
  "la roux",
  "miles davis",
  "nessa barrett",
  "memo boy",
  "king crimson",
  "flying lotus",
  "regina spektor",
  "birdy",
  "jihyo",
  "belle and sebastian",
  "janet jackson",
  "deadmau5",
  "daughter",
  "shania twain",
  "royal blood",
  "baby smoove",
  "the last shadow puppets",
  "ludwig göransson",
  "nardo wick",
  "hollywood undead",
  "mom jeans.",
  "waka flocka flame",
  "norah jones",
  "britney manson",
  "brandy",
  "cake",
  "the temper trap",
  "n.w.a.",
  "violent femmes",
  "nancy sinatra",
  "jeon somi",
  "zz top",
  "lucidbeatz",
  "p1harmony",
  "flo",
  "odd future",
  "bronski beat",
  "k.flay",
  "yehezkel raz",
  "lancey foux",
  "brian eno",
  "don mclean",
  "steve miller band",
  "dreamcatcher",
  "maisie peters",
  "grentperez",
  "big red machine",
  "cazuza",
  "five finger death punch",
  "other nothing",
  "the stone roses",
  "anri",
  "motörhead",
  "fletcher",
  "margo guryan",
  "wings",
  "taking back sunday",
  "the cars",
  "unknown mortal orchestra",
  "bauhaus",
  "jin",
  "jefferson airplane",
  "the fratellis",
  "camille saint-saëns",
  "lyn lapid",
  "greta van fleet",
  "waterparks",
  "leonard cohen",
  "natalia lafourcade",
  "aoa",
  "idk",
  "snoh aalegra",
  "fuerza regida",
  "wilco",
  "drowning pool",
  "yo la tengo",
  "rascal flatts",
  "shy smith",
  "yes",
  "idles",
  "got7",
  "labi siffre",
  "ace of base",
  "céline dion",
  "penelope scott",
  "fleshwater",
  "afi",
  "tems",
  "anavitória",
  "illenium",
  "benee",
  "bea miller",
  "akiaura",
  "the vamps",
  "sheck wes",
  "gilsons",
  "triples",
  "rico nasty",
  "cordae",
  "tom rosenthal",
  "loving",
  "jessie ware",
  "x ambassadors",
  "cartola",
  "taeyang",
  "powfu",
  "my head is empty",
  "grizzly bear",
  "t.i.",
  "baekhyun",
  "berlioz",
  "bobby caldwell",
  "a perfect circle",
  "cypress hill",
  "strfkr",
  "redveil",
  "yellowcard",
  "anne-marie",
  "the red jumpsuit apparatus",
  "jonas blue",
  "alexandra stan",
  "bad gyal",
  "passion pit",
  "indigo de souza",
  "beach fossils",
  "iann dior",
  "the anxiety",
  "the pharcyde",
  "fontaines d.c.",
  "buffalo springfield",
  "l7nnon",
  "jakob",
  "echo & the bunnymen",
  "janelle monáe",
  "dazey and the scouts",
  "joyner lucas",
  "angel olsen",
  "seulgi",
  "toquinho",
  "arlo parks",
  "dhruv",
  "the b-52's",
  "genesis",
  "ben e. king",
  "scissor sisters",
  "roy orbison",
  "soda stereo",
  "wasia project",
  "glen campbell",
  "jordan ward",
  "briston maroney",
  "kt tunstall",
  "julia jacklin",
  "angus & julia stone",
  "burna boy",
  "spiritbox",
  "escape the fate",
  "iyaz",
  "trey songz",
  "finger eleven",
  "indila",
  "josé gonzález",
  "el alfa",
  "i prevail",
  "colde",
  "radwimps",
  "pedro sampaio",
  "the growlers",
  "kenny loggins",
  "tenacious d",
  "neil frances",
  "dido",
  "ari abdul",
  "supergrass",
  "jax jones",
  "jay sean",
  "gerry rafferty",
  "flipturn",
  "quinnie",
  "cortex",
  "quannnic",
  "comethazine",
  "sofaygo",
  "asking alexandria",
  "thom yorke",
  "jet",
  "the doobie brothers",
  "robyn",
  "odesza",
  "soccer mommy",
  "cutting crew",
  "luci4",
  "matuê",
  "cleo sol",
  "kikuo",
  "the daughters of eve",
  "dmx",
  "vegyn",
  "the psychedelic furs",
  "the chemical brothers",
  "knucks",
  "ybn nahmir",
  "polyphia",
  "three 6 mafia",
  "jalen ngonda",
  "the used",
  "blonde redhead",
  "survivor",
  "hiatus kaiyote",
  "lil b",
  "night lovell",
  "glass beach",
  "ramirez",
  "fisher",
  "parcels",
  "redbone",
  "glaive",
  "wabie",
  "rod stewart",
  "sky ferreira",
  "nct",
  "florist",
  "com truise",
  "bob sinclar",
  "king gnu",
  "andrew underberg",
  "lin-manuel miranda",
  "joyce manor",
  "dom corleo",
  "steve aoki",
  "good morning",
  "xxanteria",
  "olly murs",
  "c. tangana",
  "crush",
  "dimitri vegas & like mike",
  "carpenters",
  "interworld",
  "sega sound team",
  "diddy",
  "shinedown",
  "austin farwell",
  "haarper",
  "yaya bey",
  "chloe x halle",
  "nico",
  "architects",
  "marília mendonça",
  "dj shadow",
  "tom petty and the heartbreakers",
  "lonely god",
  "i monster",
  "taeyong",
  "pineapple stormtv",
  "the jesus and mary chain",
  "the magnetic fields",
  "kaytraminé",
  "mark ambor",
  "kurt cobain",
  "seu jorge",
  "garbage",
  "the oh hellos",
  "sidewalks and skeletons",
  "romeo santos",
  "gloria gaynor",
  "young stoner life",
  "the boyz",
  "busta rhymes",
  "bk",
  "bone thugs-n-harmony",
  "djonga",
  "jhayco",
  "alina baraz",
  "the turtles",
  "yg",
  "sam fender",
  "monsta x",
  "hippo campus",
  "sub urban",
  "stormzy",
  "toro y moi",
  "chevelle",
  "sara bareilles",
  "chvrches",
  "rod wave",
  "bright eyes",
  "olivia newton-john",
  "iggy pop",
  "hannah montana",
  "lebanon hanover",
  "natalie imbruglia",
  "ricky martin",
  "john williams",
  "hako",
  "aventura",
  "mannequin pussy",
  "toploader",
  "skank",
  "takayoshi",
  "crowded house",
  "the flaming lips",
  "late night drive home",
  "the smile",
  "birth day",
  "grandson",
  "edward sharpe & the magnetic zeros",
  "stardust",
  "thouxanbanfauni",
  "def leppard",
  "kasabian",
  "flyingfish",
  "gang starr",
  "carole king",
  "fbc",
  "james bay",
  "sasha alex sloan",
  "malcolm todd",
  "f(x)",
  "luis miguel",
  "bibi babydoll",
  "kungs",
  "chloe moriondo",
  "jaehyun",
  "dj scheme",
  "bowling for soup",
  "boywithuke",
  "lady a",
  "the cinematic orchestra",
  "elita",
  "the radio dept.",
  "becky hill",
  "zerobaseone",
  "alice deejay",
  "lany",
  "king princess",
  "luna li",
  "michael kiwanuka",
  "death",
  "sault",
  "will smith",
  "xavi",
  "pumapjl",
  "selena",
  "eazy-e",
  "teen suicide",
  "sainté",
  "l'impératrice",
  "james hype",
  "kelis",
  "lil mabu",
  "eden",
  "jnhygs",
  "that mexican ot",
  "duckwrth",
  "t. rex",
  "lukas graham",
  "eladio carrion",
  "nena",
  "benny the butcher",
  "max richter",
  "get scared",
  "tribalistas",
  "ruel",
  "of montreal",
  "surfaces",
  "chlöe",
  "sewerslvt",
  "mount kimbie",
  "taeko onuki",
  "jaymes young",
  "natanael cano",
  "pale jay",
  "we the kings",
  "matt champion",
  "american authors",
  "the vaccines",
  "mc ig",
  "bonobo",
  "they might be giants",
  "famous dex",
  "sheryl crow",
  "d. savage",
  "slowthai",
  "tropa do bruxo",
  "pitty",
  "rebzyyx",
  "shiro sagisu",
  "chicago",
  "the roots",
  "fountains of wayne",
  "bigbang",
  "gibran alcocer",
  "coyote theory",
  "dance gavin dance",
  "kep1er",
  "jojo siwa",
  "big time rush",
  "insane clown posse",
  "saint jhn",
  "testament",
  "mario",
  "masayoshi takanaka",
  "marisa monte",
  "delaney bailey",
  "lsd and the search for god",
  "louis tomlinson",
  "mora",
  "flora matos",
  "n.e.r.d",
  "311",
  "midrift",
  "dead or alive",
  "ceelo green",
  "connor price",
  "arcángel",
  "kayblack",
  "yago oproprio",
  "the microphones",
  "rubel",
  "mccafferty",
  "mc kevin o chris",
  "four tet",
  "veigh",
  "lush",
  "whethan",
  "junior h",
  "hwasa",
  "fatboy slim",
  "rüfüs du sol",
  "pink sweat$",
  "donna summer",
  "ethereal",
  "sampha",
  "sepultura",
  "the rose",
  "half•alive",
  "hikaru utada",
  "ufo361",
  "harmless",
  "johannes brahms",
  "cass elliot",
  "zeruel",
  "elijah fox",
  "michael giacchino",
  "chase & status",
  "rick james",
  "emilia",
  "2ne1",
  "paul anka",
  "070 shake",
  "local natives",
  "the ink spots",
  "sports",
  "roxette",
  "caravan palace",
  "ginuwine",
  "nicholas britell",
  "babyface ray",
  "action bronson",
  "dpr live",
  "lyn",
  "dvrst",
  "loreen",
  "bedroom",
  "young miko",
  "duke dumont",
  "descendents",
  "siouxxie sixxsta",
  "skullcrusher",
  "dijon",
  "laura les",
  "meryl streep",
  "fkj",
  "jay park",
  "de la soul",
  "knocked loose",
  "the foundations",
  "john summit",
  "grupo frontera",
  "waxahatchee",
  "lucky daye",
  "sabaton",
  "memphis cult",
  "deco*27",
  "alice cooper",
  "emile mosseri",
  "gloria groove",
  "zillakami",
  "misamo",
  "ilytommy",
  "mandy moore",
  "slaughter beach, dog",
  "filipe ret",
  "cobra starship",
  "oscar lang",
  "brand new",
  "vashti bunyan",
  "mimideath",
  "starflyer 59",
  "osamason",
  "pat benatar",
  "godsmack",
  "peachy!",
  "haddaway",
  "regard",
  "phillipa soo",
  "john coltrane",
  "barrett wilbert weed",
  "rich brian",
  "leon bridges",
  "anuel aa",
  "rudimental",
  "r3hab",
  "grateful dead",
  "the mountain goats",
  "s3bzs",
  "miniature tigers",
  "missy elliott",
  "will wood",
  "adi oasis",
  "joel corry",
  "antônio carlos jobim",
  "rich the kid",
  "ikon",
  "strawberry switchblade",
  "thin lizzy",
  "riovaz",
  "kaiser chiefs",
  "ella mai",
  "cássia eller",
  "dreamer isioma",
  "dodie",
  "vaundy",
  "griff",
  "matchbox twenty",
  "racionais mc's",
  "rick astley",
  "rita ora",
  "willie nelson",
  "billlie",
  "tatsuya kitani",
  "kittie",
  "jorge & mateus",
  "luke bryan",
  "yena",
  "chris travis",
  "mos def",
  "matt sassari",
  "yeule",
  "joão gilberto",
  "the beaches",
  "nxworries",
  "chromatics",
  "dio",
  "dead kennedys",
  "the simps",
  "renée elise goldsberry",
  "latto",
  "john newman",
  "kaash paige",
  "young money",
  "g herbo",
  "luis fonsi",
  "glare",
  "jonathan groff",
  "animal collective",
  "the monkees",
  "killswitch engage",
  "ddg",
  "maggie lindemann",
  "41",
  "atarashii gakko!",
  "wisin & yandel",
  "the bangles",
  "neck deep",
  "purple disco machine",
  "in flames",
  "cass mccombs",
  "nia archives",
  "sinéad o'connor",
  "the breeders",
  "clara la san",
  "stereophonics",
  "chase shakur",
  "röyksopp",
  "the hives",
  "duda beat",
  "alphaville",
  "victorious cast",
  "durand jones & the indications",
  "run the jewels",
  "peter gabriel",
  "feeble little horse",
  "stone sour",
  "ofenbach",
  "unotheactivist",
  "ryuichi sakamoto",
  "bktherula",
  "hot mulligan",
  "marty robbins",
  "emei",
  "kelela",
  "nicky jam",
  "concrete boys",
  "michael sembello",
  "pvris",
  "mc ph",
  "jamie xx",
  "health",
  "khai dreams",
  "zerb",
  "teezo touchdown",
  "ali",
  "james brown",
  "lovejoy",
  "juanes",
  "will paquin",
  "paloma faith",
  "zac brown band",
  "julieta venegas",
  "tana",
  "bicep",
  "mach-hommy",
  "mc livinho",
  "10cc",
  "the hollies",
  "arizona zervas",
  "looking glass",
  "eric prydz",
  "aaron smith",
  "flawed mangoes",
  "zac efron",
  "bell biv devoe",
  "pooh shiesty",
  "menahan street band",
  "akira yamaoka",
  "claire rosinkranz",
  "ericdoa",
  "a flock of seagulls",
  "diana ross",
  "quadeca",
  "maria bethânia",
  "midwxst",
  "oneohtrix point never",
  "henrique & juliano",
  "james blunt",
  "banners",
  "the pretty reckless",
  "vanessa carlton",
  "black veil brides",
  "luan santana",
  "mon laferte",
  "curtis mayfield",
  "caribou",
  "cat power",
  "eartheater",
  "lloyd vaan",
  "dizzee rascal",
  "movements",
  "the cramps",
  "tz da coronel",
  "valorant",
  "normani",
  "astrud gilberto",
  "tanukichan",
  "jacquees",
  "rels b",
  "quevedo",
  "zion & lennox",
  "lloyd",
  "mayday parade",
  "wendy",
  "geek music",
  "milton nascimento",
  "los hermanos",
  "matt maeson",
  "manuel turizo",
  "holly humberstone",
  "gustavo cerati",
  "the head and the heart",
  "palaye royale",
  "tina turner",
  "celeste",
  "common",
  "tierra whack",
  "faith no more",
  "palace",
  "kard",
  "ocean alley",
  "lamb of god",
  "tom misch",
  "d'angelo",
  "ashanti",
  "sir mix-a-lot",
  "boa",
  "hayley kiyoko",
  "the romantics",
  "bss",
  "sideshow",
  "la dispute",
  "sigur rós",
  "larry june",
  "アトラスサウンドチーム",
  "aly & aj",
  "thaiboy digital",
  "kill eva",
  "morrissey",
  "eem triplin",
  "ennio morricone",
  "the isley brothers",
  "teyana taylor",
  "nct dojaejung",
  "role model",
  "awfultune",
  "the brobecks",
  "sharon van etten",
  "city morgue",
  "so!yoon!",
  "justine skye",
  "of mice & men",
  "ginger root",
  "wale",
  "patti smith",
  "starship",
  "bas",
  "rachel chinouriri",
  "staind",
  "akmu",
  "sickboyrari",
  "¿téo?",
  "searows",
  "farruko",
  "purple kiss",
  "the presidents of the united states of america",
  "mariya takeuchi",
  "the little dippers",
  "lilithzplug",
  "prince royce",
  "willis",
  "derek & the dominos",
  "mojave 3",
  "alex turner",
  "city girls",
  "bikini kill",
  "red velvet - irene & seulgi",
  "puddle of mudd",
  "slum village",
  "alberto baldan bembo",
  "bennett",
  "blind melon",
  "lit",
  "bombay bicycle club",
  "why don't we",
  "axwell /\\ ingrosso",
  "sebastian yatra",
  "band of horses",
  "horse jumper of love",
  "roy woods",
  "shoreline mafia",
  "pendulum",
  "cold war kids",
  "mars argo",
  "rainbow",
  "nightwish",
  "jessica pratt",
  "jojo",
  "mark morrison",
  "gilberto gil",
  "jaydes",
  "thousand foot krutch",
  "zhu",
  "jean dawson",
  "dj ws da igrejinha",
  "dom dolla",
  "lazer dim 700",
  "loud luxury",
  "dargz",
  "artms",
  "joost",
  "yabujin",
  "blue swede",
  "young dolph",
  "souls of mischief",
  "tomcbumpz",
  "franz liszt",
  "dej loaf",
  "maná",
  "neil diamond",
  "boys like girls",
  "domo genesis",
  "sevdaliza",
  "matheus & kauan",
  "macklemore",
  "the moldy peaches",
  "exid",
  "the driver era",
  "benny benassi",
  "spoon",
  "los enanitos verdes",
  "built to spill",
  "d12",
  "tennis",
  "that mexican ot",
  "duckwrth",
  "t. rex",
  "lukas graham",
  "eladio carrion",
  "nena",
  "benny the butcher",
  "max richter",
  "get scared",
  "tribalistas",
  "ruel",
  "of montreal",
  "surfaces",
  "chlöe",
  "sewerslvt",
  "mount kimbie",
  "taeko onuki",
  "jaymes young",
  "natanael cano",
  "pale jay",
  "we the kings",
  "matt champion",
  "american authors",
  "the vaccines",
  "mc ig",
  "bonobo",
  "they might be giants",
  "famous dex",
  "sheryl crow",
  "d. savage",
  "slowthai",
  "tropa do bruxo",
  "pitty",
  "rebzyyx",
  "shiro sagisu",
  "chicago",
  "the roots",
  "fountains of wayne",
  "bigbang",
  "gibran alcocer",
  "coyote theory",
  "dance gavin dance",
  "kep1er",
  "jojo siwa",
  "big time rush",
  "insane clown posse",
  "saint jhn",
  "testament",
  "mario",
  "masayoshi takanaka",
  "marisa monte",
  "delaney bailey",
  "lsd and the search for god",
  "louis tomlinson",
  "mora",
  "flora matos",
  "n.e.r.d",
  "311",
  "midrift",
  "dead or alive",
  "ceelo green",
  "connor price",
  "arcángel",
  "kayblack",
  "yago oproprio",
  "the microphones",
  "rubel",
  "mccafferty",
  "mc kevin o chris",
  "four tet",
  "veigh",
  "lush",
  "whethan",
  "junior h",
  "hwasa",
  "fatboy slim",
  "rüfüs du sol",
  "pink sweat$",
  "donna summer",
  "ethereal",
  "sampha",
  "sepultura",
  "the rose",
  "half•alive",
  "hikaru utada",
  "ufo361",
  "harmless",
  "johannes brahms",
  "cass elliot",
  "zeruel",
  "elijah fox",
  "michael giacchino",
  "chase & status",
  "rick james",
  "emilia",
  "2ne1",
  "paul anka",
  "070 shake",
  "local natives",
  "the ink spots",
  "sports",
  "roxette",
  "caravan palace",
  "ginuwine",
  "nicholas britell",
  "babyface ray",
  "action bronson",
  "dpr live",
  "lyn",
  "dvrst",
  "loreen",
  "bedroom",
  "young miko",
  "duke dumont",
  "descendents",
  "siouxxie sixxsta",
  "skullcrusher",
  "dijon",
  "laura les",
  "meryl streep",
  "fkj",
  "jay park",
  "de la soul",
  "knocked loose",
  "the foundations",
  "john summit",
  "grupo frontera",
  "waxahatchee",
  "lucky daye",
  "sabaton",
  "memphis cult",
  "deco*27",
  "alice cooper",
  "emile mosseri",
  "gloria groove",
  "zillakami",
  "misamo",
  "ilytommy",
  "mandy moore",
  "slaughter beach, dog",
  "filipe ret",
  "cobra starship",
  "oscar lang",
  "brand new",
  "vashti bunyan",
  "mimideath",
  "starflyer 59",
  "osamason",
  "pat benatar",
  "godsmack",
  "peachy!",
  "haddaway",
  "regard",
  "phillipa soo",
  "john coltrane",
  "barrett wilbert weed",
  "rich brian",
  "leon bridges",
  "anuel aa",
  "rudimental",
  "r3hab",
  "grateful dead",
  "the mountain goats",
  "s3bzs",
  "miniature tigers",
  "missy elliott",
  "will wood",
  "adi oasis",
  "joel corry",
  "antônio carlos jobim",
  "rich the kid",
  "ikon",
  "strawberry switchblade",
  "thin lizzy",
  "riovaz",
  "kaiser chiefs",
  "ella mai",
  "cássia eller",
  "dreamer isioma",
  "dodie",
  "vaundy",
  "griff",
  "matchbox twenty",
  "racionais mc's",
  "rick astley",
  "rita ora",
  "willie nelson",
  "billlie",
  "tatsuya kitani",
  "kittie",
  "jorge & mateus",
  "luke bryan",
  "yena",
  "chris travis",
  "mos def",
  "matt sassari",
  "yeule",
  "joão gilberto",
  "the beaches",
  "nxworries",
  "chromatics",
  "dio",
  "dead kennedys",
  "the simps",
  "renée elise goldsberry",
  "latto",
  "john newman",
  "kaash paige",
  "young money",
  "g herbo",
  "luis fonsi",
  "glare",
  "jonathan groff",
  "animal collective",
  "the monkees",
  "killswitch engage",
  "ddg",
  "maggie lindemann",
  "41",
  "atarashii gakko!",
  "wisin & yandel",
  "the bangles",
  "neck deep",
  "purple disco machine",
  "in flames",
  "cass mccombs",
  "nia archives",
  "sinéad o'connor",
  "the breeders",
  "clara la san",
  "stereophonics",
  "chase shakur",
  "röyksopp",
  "the hives",
  "duda beat",
  "alphaville",
  "victorious cast",
  "durand jones & the indications",
  "run the jewels",
  "peter gabriel",
  "feeble little horse",
  "stone sour",
  "ofenbach",
  "unotheactivist",
  "ryuichi sakamoto",
  "bktherula",
  "hot mulligan",
  "marty robbins",
  "emei",
  "kelela",
  "nicky jam",
  "concrete boys",
  "michael sembello",
  "pvris",
  "mc ph",
  "jamie xx",
  "health",
  "khai dreams",
  "zerb",
  "teezo touchdown",
  "ali",
  "james brown",
  "lovejoy",
  "juanes",
  "will paquin",
  "paloma faith",
  "zac brown band",
  "julieta venegas",
  "tana",
  "bicep",
  "mach-hommy",
  "mc livinho",
  "10cc",
  "the hollies",
  "arizona zervas",
  "looking glass",
  "eric prydz",
  "aaron smith",
  "flawed mangoes",
  "zac efron",
  "bell biv devoe",
  "pooh shiesty",
  "menahan street band",
  "akira yamaoka",
  "claire rosinkranz",
  "ericdoa",
  "a flock of seagulls",
  "diana ross",
  "quadeca",
  "maria bethânia",
  "midwxst",
  "oneohtrix point never",
  "henrique & juliano",
  "james blunt",
  "banners",
  "the pretty reckless",
  "vanessa carlton",
  "black veil brides",
  "luan santana",
  "mon laferte",
  "curtis mayfield",
  "caribou",
  "cat power",
  "eartheater",
  "lloyd vaan",
  "dizzee rascal",
  "movements",
  "the cramps",
  "tz da coronel",
  "valorant",
  "normani",
  "astrud gilberto",
  "tanukichan",
  "jacquees",
  "rels b",
  "quevedo",
  "zion & lennox",
  "lloyd",
  "mayday parade",
  "wendy",
  "geek music",
  "milton nascimento",
  "los hermanos",
  "matt maeson",
  "manuel turizo",
  "holly humberstone",
  "gustavo cerati",
  "the head and the heart",
  "palaye royale",
  "tina turner",
  "celeste",
  "common",
  "tierra whack",
  "faith no more",
  "palace",
  "kard",
  "ocean alley",
  "lamb of god",
  "tom misch",
  "d'angelo",
  "ashanti",
  "sir mix-a-lot",
  "boa",
  "hayley kiyoko",
  "the romantics",
  "bss",
  "sideshow",
  "la dispute",
  "sigur rós",
  "larry june",
  "アトラスサウンドチーム",
  "aly & aj",
  "thaiboy digital",
  "kill eva",
  "morrissey",
  "eem triplin",
  "ennio morricone",
  "the isley brothers",
  "teyana taylor",
  "nct dojaejung",
  "role model",
  "awfultune",
  "the brobecks",
  "sharon van etten",
  "city morgue",
  "so!yoon!",
  "justine skye",
  "of mice & men",
  "ginger root",
  "wale",
  "patti smith",
  "starship",
  "bas",
  "rachel chinouriri",
  "staind",
  "akmu",
  "sickboyrari",
  "¿téo?",
  "searows",
  "farruko",
  "purple kiss",
  "the presidents of the united states of america",
  "mariya takeuchi",
  "the little dippers",
  "lilithzplug",
  "prince royce",
  "willis",
  "derek & the dominos",
  "mojave 3",
  "alex turner",
  "city girls",
  "bikini kill",
  "red velvet - irene & seulgi",
  "puddle of mudd",
  "slum village",
  "alberto baldan bembo",
  "bennett",
  "blind melon",
  "lit",
  "bombay bicycle club",
  "why don't we",
  "axwell /\\ ingrosso",
  "sebastian yatra",
  "band of horses",
  "horse jumper of love",
  "roy woods",
  "shoreline mafia",
  "pendulum",
  "cold war kids",
  "mars argo",
  "rainbow",
  "nightwish",
  "jessica pratt",
  "jojo",
  "mark morrison",
  "gilberto gil",
  "jaydes",
  "thousand foot krutch",
  "zhu",
  "jean dawson",
  "dj ws da igrejinha",
  "dom dolla",
  "lazer dim 700",
  "loud luxury",
  "dargz",
  "artms",
  "joost",
  "yabujin",
  "blue swede",
  "young dolph",
  "souls of mischief",
  "tomcbumpz",
  "franz liszt",
  "dej loaf",
  "maná",
  "neil diamond",
  "boys like girls",
  "domo genesis",
  "sevdaliza",
  "matheus & kauan",
  "macklemore",
  "the moldy peaches",
  "exid",
  "the driver era",
  "benny benassi",
  "spoon",
  "los enanitos verdes",
  "built to spill",
  "d12",
  "tennis",
  "fat joe",
  "omori",
  "john travolta",
  "ezekiel",
  "rag'n'bone man",
  "rachel zegler",
  "rscl",
  "rosa walton",
  "videoclub",
  "lionel richie",
  "rupaul",
  "steppenwolf",
  "lelio luttazzi",
  "los retros",
  "odd eye circle (artms)",
  "diiv",
  "the carters",
  "empress of",
  "heize",
  "calum scott",
  "kublai khan tx",
  "injury reserve",
  "oh my girl",
  "king harvest",
  "the postal service",
  "ice nine kills",
  "danger mouse",
  "zelooperz",
  "tini",
  "killer mike",
  "maribou state",
  "black midi",
  "tom jones",
  "burial",
  "kana-boon",
  "ben platt",
  "the stranglers",
  "guided by voices",
  "minnie riperton",
  "caamp",
  "alien ant farm",
  "the crane wives",
  "madeintyo",
  "hollow coves",
  "seatbelts",
  "temporex",
  "snail mail",
  "herb alpert & the tijuana brass",
  "a$ap ant",
  "you'll never get to heaven",
  "the scotts",
  "one ok rock",
  "grupo revelação",
  "vanessa da mata",
  "sisters of mercy",
  "wire",
  "awolnation",
  "zwe1hvndxr",
  "duke ellington",
  "blp kosher",
  "ben howard",
  "hemlocke springs",
  "cody fry",
  "duncan laurence",
  "devo",
  "sohodolls",
  "frank zappa",
  "mike will made-it",
  "goth babe",
  "got the beat",
  "honne",
  "jacob collier",
  "paolo nutini",
  "alexandre desplat",
  "fabrizio paterlini",
  "aqua",
  "commodores",
  "the score",
  "syko",
  "lagum",
  "benji blue bills",
  "czarface",
  "troy",
  "ajj",
  "tv on the radio",
  "superm",
  "apink",
  "jo yuri",
  "maurice ravel",
  "aimer",
  "paulie leparik",
  "ravyn lenae",
  "grupo menos é mais",
  "jon bellion",
  "the plot in you",
  "rupert holmes",
  "trueno",
  "underworld",
  "tones and i",
  "4 non blondes",
  "polaris",
  "ramin djawadi",
  "jeff rosenstock",
  "echosmith",
  "cheat codes",
  "cursedevil",
  "weeekly",
  "orochi",
  "pouya",
  "odie",
  "emmit fenn",
  "jagger finn",
  "nettspend",
  "takeoff",
  "daya",
  "glokk40spaz",
  "ic3peak",
  "primus",
  "colter wall",
  "cupcakke",
  "tom waits",
  "rejjie snow",
  "s3rl",
  "miranda!",
  "wage war",
  "barry manilow",
  "skaiwater",
  "peter mcpoland",
  "the alan parsons project",
  "mastodon",
  "idina menzel",
  "jeremy soule",
  "clc",
  "pink siifu",
  "video days",
  "french 79",
  "antonín dvořák",
  "orville peck",
  "culture club",
  "the war on drugs",
  "europe",
  "ten",
  "coi leray",
  "vulfpeck",
  "jp saxe",
  "woodkid",
  "imase",
  "alabama shakes",
  "harry nilsson",
  "future islands",
  "turnover",
  "jóhann jóhannsson",
  "hiroyuki sawano",
  "bob seger",
  "the proclaimers",
  "crosby, stills, nash & young",
  "cris mj",
  "dinosaur jr.",
  "lomelda",
  "amerie",
  "ian",
  "σtella",
  "disco lines",
  "mc cabelinho",
  "andy grammer",
  "kensuke ushio",
  "zoé",
  "noname",
  "money man",
  "verzache",
  "trevor daniel",
  "the dresden dolls",
  "treasure",
  "naughty boy",
  "wjsn",
  "sim",
  "oingo boingo",
  "the libertines",
  "lp",
  "edvard grieg",
  "belanova",
  "piero umiliani",
  "80purppp",
  "allman brothers band",
  "huey lewis & the news",
  "talk talk",
  "la bouche",
  "loossemble",
  "pentagon",
  "london grammar",
  "mk.gee",
  "asian kung-fu generation",
  "drugdealer",
  "underscores",
  "swv",
  "washed out",
  "addison rae",
  "ren",
  "sunny day real estate",
  "nicky youre",
  "the story so far",
  "jitwam",
  "ub40",
  "dragonforce",
  "goreshit",
  "deerhunter",
  "pnb rock",
  "sarah kinsley",
  "boynextdoor",
  "tws",
  "princess chelsea",
  "candy claws",
  "sematary",
  "blake roman",
  "madness",
  "baby tate",
  "bush",
  "scarlet house",
  "courtney barnett",
  "chris lake",
  "jev.",
  "blanco",
  "zé neto & cristiano",
  "knxwledge",
  "mudvayne",
  "rory in early 20s",
  "frankie valli & the four seasons",
  "bad suns",
  "black box recorder",
  "iv of spades",
  "luther vandross",
  "brakence",
  "paul russell",
  "the rah band",
  "ari lennox",
  "momoland",
  "lee hyori",
  "eliza rose",
  "42 dugg",
  "sex pistols",
  "everything but the girl",
  "olivia dean",
  "amy macdonald",
  "basement jaxx",
  "orchestral manoeuvres in the dark",
  "duwap kaine",
  "sg lewis",
  "pritam",
  "citizen",
  "mick gordon",
  "rosa linn",
  "tk from ling tosite sigure",
  "camelphat",
  "･ ･－･ ･－ ･･･ ･ －･･",
  "rosé",
  "yebba",
  "christopher jackson",
  "brenda lee",
  "alceu valença",
  "bloodhound gang",
  "laura branigan",
  "potsu",
  "die antwoord",
  "stellar",
  "gigi d'agostino",
  "daste.",
  "billy talent",
  "maya hawke",
  "a. g. cook",
  "alcione",
  "enya",
  "crisaunt",
  "vengaboys",
  "leona lewis",
  "kc & the sunshine band",
  "arc de soleil",
  "huh yunjin",
  "colbie caillat",
  "skeeter davis",
  "headie one",
  "tokyopill",
  "danny elfman",
  "natiruts",
  "santigold",
  "veeze",
  "quinn",
  "serani poji",
  "fam0uz",
  "project pat",
  "george clanton",
  "the orion experience",
  "edison lighthouse",
  "vøj",
  "françoise hardy",
  "huncho jack",
  "rixton",
  "instupendo",
  "high school musical cast",
  "the moody blues",
  "eternxlkz",
  "dram",
  "banks",
  "seal",
  "george benson",
  "yann tiersen",
  "lustra",
  "the buggles",
  "germ",
  "q",
  "madcon",
  "gus dapperton",
  "kets4eki",
  "inhaler",
  "opeth",
  "skindred",
  "j hus",
  "jovem dionisio",
  "four tops",
  "urias",
  "tori kelly",
  "super guitar bros",
  "the buttertones",
  "the voidz",
  "we came as romans",
  "tim mcgraw",
  "halestorm",
  "starset",
  "the raconteurs",
  "hermanos gutiérrez",
  "snow strippers",
  "imogen heap",
  "chaka khan",
  "bad religion",
  "melody's echo chamber",
  "suicidal tendencies",
  "odecore",
  "wizkid",
  "iza",
  "wolfmother",
  "travie mccoy",
  "ebony",
  "lil ugly mane",
  "kevin gates",
  "omi",
  "feist",
  "deorro",
  "oliver heldens",
  "unlike pluto",
  "blackstreet",
  "tai verdes",
  "felix jaehn",
  "kid bloom",
  "pretty sick",
  "bakermat",
  "snakehips",
  "cheap trick",
  "vertigoaway",
  "luke hemmings",
  "mc paiva zs",
  "woodz",
  "narrow head",
  "hojean",
  "the veronicas",
  "electric callboy",
  "roxy music",
  "andrew bird",
  "jace!",
  "zico",
  "hippie sabotage",
  "maretu",
  "supermode",
  "mediavolo",
  "1999 write the future",
  "avenoir",
  "dynamicduo",
  "os paralamas do sucesso",
  "billie marten",
  "creeds",
  "duki",
  "black pumas",
  "chase icon",
  "dream theater",
  "the runaways",
  "jisoo",
  "elio",
  "ckay",
  "franz schubert",
  "iamjakehill",
  "herbie hancock",
  "flatsound",
  "eels",
  "the happy fits",
  "bobby darin",
  "punkinloveee",
  "vulgo fk",
  "o terno",
  "bill evans",
  "rio da yung og",
  "j.j. cale",
  "mike dimes",
  "dan + shay",
  "alexandra savior",
  "ludacris",
  "tokischa",
  "funkadelic",
  "godard",
  "sorority noise",
  "titãs",
  "machinedrum",
  "xtc",
  "danzig",
  "warren g",
  "sergei rachmaninoff",
  "bossman dlow",
  "amanda seyfried",
  "k'naan",
  "only the family",
  "dope lemon",
  "liam payne",
  "p.o.d.",
  "mariah the scientist",
  "nova",
  "sodikken",
  "dj arana",
  "kate bollinger",
  "the band",
  "luude",
  "d.o.d",
  "berlin",
  "robin thicke",
  "dmitri shostakovich",
  "péricles",
  "neiked",
  "icytwat",
  "static-x",
  "lifehouse",
  "belinda carlisle",
  "jack ü",
  "the oral cigarettes",
  "aries",
  "lavern",
  "silverstein",
  "cobrah",
  "annenmaykantereit",
  "gryffin",
  "young posse",
  "aj tracey",
  "marcy playground",
  "loyle carner",
  "turma do pagode",
  "brit smith",
  "oh wonder",
  "alex warren",
  "sheff g",
  "jean sibelius",
  "beartooth",
  "kenny mason",
  "volbeat",
  "donovan",
  "b.i",
  "sun kil moon",
  "basshunter",
  "ladytron",
  "ella henderson",
  "starry cat",
  "lf system",
  "rick springfield",
  "terrace martin",
  "agroplay",
  "nico & vinz",
  "the replacements",
  "café tacvba",
  "emicida",
  "adoniran barbosa",
  "original broadway cast of hamilton",
  "aidan bissett",
  "ryan mccartan",
  "raul seixas",
  "poor man's poison",
  "martin solveig",
  "hot freaks",
  "izaya tiji",
  "ski aggu",
  "hyuna",
  "vintage culture",
  "ka$hdami",
  "ross lynch",
  "alexander 23",
  "thefatrat",
  "los ángeles azules",
  "rocco",
  "barry can't swim",
  "dj gbr",
  "daisy jones & the six",
  "ana frango elétrico",
  "moe shop",
  "danny ocean",
  "barretta",
  "teto",
  "inna",
  "the scotts",
  "one ok rock",
  "grupo revelação",
  "vanessa da mata",
  "sisters of mercy",
  "wire",
  "awolnation",
  "zwe1hvndxr",
  "duke ellington",
  "blp kosher",
  "ben howard",
  "hemlocke springs",
  "cody fry",
  "duncan laurence",
  "devo",
  "sohodolls",
  "frank zappa",
  "mike will made-it",
  "goth babe",
  "got the beat",
  "honne",
  "jacob collier",
  "paolo nutini",
  "alexandre desplat",
  "fabrizio paterlini",
  "aqua",
  "commodores",
  "the score",
  "syko",
  "lagum",
  "benji blue bills",
  "czarface",
  "troy",
  "ajj",
  "tv on the radio",
  "superm",
  "apink",
  "jo yuri",
  "maurice ravel",
  "aimer",
  "paulie leparik",
  "ravyn lenae",
  "grupo menos é mais",
  "jon bellion",
  "the plot in you",
  "rupert holmes",
  "trueno",
  "underworld",
  "tones and i",
  "4 non blondes",
  "polaris",
  "ramin djawadi",
  "jeff rosenstock",
  "echosmith",
  "cheat codes",
  "cursedevil",
  "weeekly",
  "orochi",
  "pouya",
  "odie",
  "emmit fenn",
  "jagger finn",
  "nettspend",
  "takeoff",
  "daya",
  "glokk40spaz",
  "ic3peak",
  "primus",
  "colter wall",
  "cupcakke",
  "tom waits",
  "rejjie snow",
  "s3rl",
  "miranda!",
  "wage war",
  "barry manilow",
  "skaiwater",
  "peter mcpoland",
  "the alan parsons project",
  "mastodon",
  "idina menzel",
  "jeremy soule",
  "clc",
  "pink siifu",
  "video days",
  "french 79",
  "antonín dvořák",
  "orville peck",
  "culture club",
  "the war on drugs",
  "europe",
  "ten",
  "coi leray",
  "vulfpeck",
  "jp saxe",
  "woodkid",
  "imase",
  "alabama shakes",
  "harry nilsson",
  "future islands",
  "turnover",
  "jóhann jóhannsson",
  "hiroyuki sawano",
  "bob seger",
  "the proclaimers",
  "crosby, stills, nash & young",
  "cris mj",
  "dinosaur jr.",
  "lomelda",
  "amerie",
  "ian",
  "σtella",
  "disco lines",
  "mc cabelinho",
  "andy grammer",
  "kensuke ushio",
  "zoé",
  "noname",
  "money man",
  "verzache",
  "trevor daniel",
  "the dresden dolls",
  "treasure",
  "naughty boy",
  "wjsn",
  "sim",
  "oingo boingo",
  "the libertines",
  "lp",
  "edvard grieg",
  "belanova",
  "piero umiliani",
  "80purppp",
  "allman brothers band",
  "huey lewis & the news",
  "talk talk",
  "la bouche",
  "loossemble",
  "pentagon",
  "london grammar",
  "mk.gee",
  "asian kung-fu generation",
  "drugdealer",
  "underscores",
  "swv",
  "washed out",
  "addison rae",
  "ren",
  "sunny day real estate",
  "nicky youre",
  "the story so far",
  "jitwam",
  "ub40",
  "dragonforce",
  "goreshit",
  "deerhunter",
  "pnb rock",
  "sarah kinsley",
  "boynextdoor",
  "tws",
  "princess chelsea",
  "candy claws",
  "sematary",
  "blake roman",
  "madness",
  "baby tate",
  "bush",
  "scarlet house",
  "courtney barnett",
  "chris lake",
  "jev.",
  "blanco",
  "zé neto & cristiano",
  "knxwledge",
  "mudvayne",
  "rory in early 20s",
  "frankie valli & the four seasons",
  "bad suns",
  "black box recorder",
  "iv of spades",
  "luther vandross",
  "brakence",
  "paul russell",
  "the rah band",
  "ari lennox",
  "momoland",
  "lee hyori",
  "eliza rose",
  "42 dugg",
  "sex pistols",
  "everything but the girl",
  "olivia dean",
  "amy macdonald",
  "basement jaxx",
  "orchestral manoeuvres in the dark",
  "duwap kaine",
  "sg lewis",
  "pritam",
  "citizen",
  "mick gordon",
  "rosa linn",
  "tk from ling tosite sigure",
  "camelphat",
  "･ ･－･ ･－ ･･･ ･ －･･",
  "rosé",
  "yebba",
  "christopher jackson",
  "brenda lee",
  "alceu valença",
  "bloodhound gang",
  "laura branigan",
  "potsu",
  "die antwoord",
  "stellar",
  "gigi d'agostino",
  "daste.",
  "billy talent",
  "maya hawke",
  "a. g. cook",
  "alcione",
  "enya",
  "crisaunt",
  "vengaboys",
  "leona lewis",
  "kc & the sunshine band",
  "arc de soleil",
  "huh yunjin",
  "colbie caillat",
  "skeeter davis",
  "headie one",
  "tokyopill",
  "danny elfman",
  "natiruts",
  "santigold",
  "veeze",
  "quinn",
  "serani poji",
  "fam0uz",
  "project pat",
  "george clanton",
  "the orion experience",
  "edison lighthouse",
  "vøj",
  "françoise hardy",
  "huncho jack",
  "rixton",
  "instupendo",
  "high school musical cast",
  "the moody blues",
  "eternxlkz",
  "dram",
  "banks",
  "seal",
  "george benson",
  "yann tiersen",
  "lustra",
  "the buggles",
  "germ",
  "q",
  "madcon",
  "gus dapperton",
  "kets4eki",
  "inhaler",
  "opeth",
  "skindred",
  "j hus",
  "jovem dionisio",
  "four tops",
  "urias",
  "tori kelly",
  "super guitar bros",
  "the buttertones",
  "the voidz",
  "we came as romans",
  "tim mcgraw",
  "halestorm",
  "starset",
  "the raconteurs",
  "hermanos gutiérrez",
  "snow strippers",
  "imogen heap",
  "chaka khan",
  "bad religion",
  "melody's echo chamber",
  "suicidal tendencies",
  "odecore",
  "wizkid",
  "iza",
  "wolfmother",
  "travie mccoy",
  "ebony",
  "lil ugly mane",
  "kevin gates",
  "omi",
  "feist",
  "deorro",
  "oliver heldens",
  "unlike pluto",
  "blackstreet",
  "tai verdes",
  "felix jaehn",
  "kid bloom",
  "pretty sick",
  "bakermat",
  "snakehips",
  "cheap trick",
  "vertigoaway",
  "luke hemmings",
  "mc paiva zs",
  "woodz",
  "narrow head",
  "hojean",
  "the veronicas",
  "electric callboy",
  "roxy music",
  "andrew bird",
  "jace!",
  "zico",
  "hippie sabotage",
  "maretu",
  "supermode",
  "mediavolo",
  "1999 write the future",
  "avenoir",
  "dynamicduo",
  "os paralamas do sucesso",
  "billie marten",
  "creeds",
  "duki",
  "black pumas",
  "chase icon",
  "dream theater",
  "the runaways",
  "jisoo",
  "elio",
  "ckay",
  "franz schubert",
  "iamjakehill",
  "herbie hancock",
  "flatsound",
  "eels",
  "the happy fits",
  "bobby darin",
  "punkinloveee",
  "vulgo fk",
  "o terno",
  "bill evans",
  "rio da yung og",
  "j.j. cale",
  "mike dimes",
  "dan + shay",
  "alexandra savior",
  "ludacris",
  "tokischa",
  "funkadelic",
  "godard",
  "sorority noise",
  "titãs",
  "machinedrum",
  "xtc",
  "danzig",
  "warren g",
  "sergei rachmaninoff",
  "bossman dlow",
  "amanda seyfried",
  "k'naan",
  "only the family",
  "dope lemon",
  "liam payne",
  "p.o.d.",
  "mariah the scientist",
  "nova",
  "sodikken",
  "dj arana",
  "kate bollinger",
  "the band",
  "luude",
  "d.o.d",
  "berlin",
  "robin thicke",
  "dmitri shostakovich",
  "péricles",
  "neiked",
  "icytwat",
  "static-x",
  "lifehouse",
  "belinda carlisle",
  "jack ü",
  "the oral cigarettes",
  "aries",
  "lavern",
  "silverstein",
  "cobrah",
  "annenmaykantereit",
  "gryffin",
  "young posse",
  "aj tracey",
  "marcy playground",
  "loyle carner",
  "turma do pagode",
  "brit smith",
  "oh wonder",
  "alex warren",
  "sheff g",
  "jean sibelius",
  "beartooth",
  "kenny mason",
  "volbeat",
  "donovan",
  "b.i",
  "sun kil moon",
  "basshunter",
  "ladytron",
  "ella henderson",
  "starry cat",
  "lf system",
  "rick springfield",
  "terrace martin",
  "agroplay",
  "nico & vinz",
  "the replacements",
  "café tacvba",
  "emicida",
  "adoniran barbosa",
  "original broadway cast of hamilton",
  "aidan bissett",
  "ryan mccartan",
  "raul seixas",
  "poor man's poison",
  "martin solveig",
  "hot freaks",
  "izaya tiji",
  "ski aggu",
  "hyuna",
  "vintage culture",
  "ka$hdami",
  "ross lynch",
  "alexander 23",
  "thefatrat",
  "los ángeles azules",
  "rocco",
  "barry can't swim",
  "dj gbr",
  "daisy jones & the six",
  "ana frango elétrico",
  "moe shop",
  "danny ocean",
  "barretta",
  "teto",
  "inna",
  "sex bob-omb",
  "fivio foreign",
  "the dave brubeck quartet",
  "平田志穂子",
  "puzzle",
  "piri",
  "reo speedwagon",
  "thrown",
  "badfinger",
  "yaeji",
  "jasiah",
  "twisted sister",
  "new radicals",
  "little dragon",
  "the black skirts",
  "james taylor",
  "lô borges",
  "hombres g",
  "jazmin bean",
  "spandau ballet",
  "clarice falcão",
  "house of pain",
  "blondshell",
  "within temptation",
  "swans",
  "viktor vaughn",
  "squarepusher",
  "big pun",
  "viagra boys",
  "sugar ray",
  "olivia o'brien",
  "heartsteel",
  "trivium",
  "todd rundgren",
  "310babii",
  "ja rule",
  "marlon craft",
  "a.r. rahman",
  "zack fox",
  "kid abelha",
  "howard shore",
  "saygrace",
  "curren$y",
  "ovlov",
  "whitesnake",
  "anamanaguchi",
  "hugh jackman",
  "tr",
  "yhapojj",
  "ley soul",
  "earthgang",
  "em beihold",
  "édith piaf",
  "simply red",
  "gang of youths",
  "d-block europe",
  "kanaria",
  "hensonn",
  "broken social scene",
  "gusttavo lima",
  "him",
  "raveena",
  "big l",
  "origami angel",
  "war",
  "the maine",
  "daniel johnston",
  "ryan castro",
  "terno rei",
  "belchior",
  "ritchie valens",
  "ana castela",
  "silversun pickups",
  "kay flock",
  "toshifumi hinata",
  "dj jeeh fdc",
  "dermot kennedy",
  "the chicks",
  "bol4",
  "hoyo-mix",
  "metro station",
  "03 greedo",
  "kristen bell",
  "first aid kit",
  "wiu",
  "thomas rhett",
  "gerard way",
  "the supremes",
  "desiigner",
  "tasha & tracie",
  "janis joplin",
  "la oreja de van gogh",
  "tegan and sara",
  "juicy j",
  "fishmans",
  "cannons",
  "ivri",
  "inner wave",
  "two shell",
  "styx",
  "earthgang",
  "em beihold",
  "édith piaf",
  "simply red",
  "gang of youths",
  "d-block europe",
  "kanaria",
  "hensonn",
  "broken social scene",
  "gusttavo lima",
  "him",
  "raveena",
  "big l",
  "origami angel",
  "war",
  "the maine",
  "daniel johnston",
  "ryan castro",
  "terno rei",
  "belchior",
  "ritchie valens",
  "ana castela",
  "silversun pickups",
  "kay flock",
  "toshifumi hinata",
  "dj jeeh fdc",
  "dermot kennedy",
  "the chicks",
  "bol4",
  "hoyo-mix",
  "metro station",
  "03 greedo",
  "kristen bell",
  "first aid kit",
  "wiu",
  "thomas rhett",
  "gerard way",
  "the supremes",
  "desiigner",
  "tasha & tracie",
  "janis joplin",
  "la oreja de van gogh",
  "tegan and sara",
  "juicy j",
  "fishmans",
  "cannons",
  "ivri",
  "inner wave",
  "two shell",
  "styx",
  "wwe",
  "sticky fingers",
  "mabel",
  "o rappa",
  "anarbor",
  "linked horizon",
  "looπδ / odd eye circle",
  "crosby, stills & nash",
  "varg²™",
  "toby keith",
  "minor threat",
  "mahmood",
  "agnes obel",
  "emotional oranges",
  "dxrk ダーク",
  "caskets",
  "manny laurenko",
  "rob $tone",
  "soap&skin",
  "yoko takahashi",
  "pale saints",
  "jackson wang",
  "tvxq!",
  "kenny chesney",
  "gala",
  "ivete sangalo",
  "kina",
  "currents",
  "arthur verocai",
  "maximum the hormone",
  "he is we",
  "conway the machine",
  "zella day",
  "thievery corporation",
  "official hige dandism",
  "luna",
  "yunk vino",
  "daughtry",
  "reol",
  "timmy trumpet",
  "yorushika",
  "flight facilities",
  "jamie foxx",
  "everything everything",
  "gza",
  "cher lloyd",
  "bread beatz",
  "gayle",
  "the jam",
  "pearly drops",
  "the righteous brothers",
  "exo-k",
  "cannibal corpse",
  "the struts",
  "lovelytheband",
  "henry mancini",
  "jt music",
  "mrs. green apple",
  "cosmo sheldrake",
  "beirut",
  "ok go",
  "andrew lloyd webber",
  "upsahl",
  "baby bash",
  "john vincent iii",
  "morat",
  "the lovin' spoonful",
  "studio killers",
  "novos baianos",
  "buzzcocks",
  "bobby shmurda",
  "alan jackson",
  "the stooges",
  "gemini",
  "pup",
  "hardrock",
  "katya lel",
  "the specials",
  "the decemberists",
  "joy crookes",
  "yungatita",
  "myles smith",
  "joep beving",
  "smokey robinson & the miracles",
  "ricky nelson",
  "angèle",
  "mupp",
  "ride",
  "key",
  "miss a",
  "jason aldean",
  "sofi tukker",
  "d.o.",
  "ikimonogakari",
  "sharon jones & the dap-kings",
  "dion",
  "tommy james & the shondells",
  "the brian jonestown massacre",
  "don diablo",
  "julien baker",
  "the righteous brothers",
  "exo-k",
  "cannibal corpse",
  "the struts",
  "lovelytheband",
  "henry mancini",
  "jt music",
  "mrs. green apple",
  "cosmo sheldrake",
  "beirut",
  "ok go",
  "andrew lloyd webber",
  "upsahl",
  "baby bash",
  "john vincent iii",
  "morat",
  "the lovin' spoonful",
  "studio killers",
  "novos baianos",
  "buzzcocks",
  "bobby shmurda",
  "alan jackson",
  "the stooges",
  "gemini",
  "pup",
  "hardrock",
  "katya lel",
  "the specials",
  "the decemberists",
  "joy crookes",
  "yungatita",
  "myles smith",
  "joep beving",
  "smokey robinson & the miracles",
  "ricky nelson",
  "angèle",
  "mupp",
  "ride",
  "key",
  "miss a",
  "jason aldean",
  "sofi tukker",
  "d.o.",
  "ikimonogakari",
  "sharon jones & the dap-kings",
  "dion",
  "tommy james & the shondells",
  "the brian jonestown massacre",
  "don diablo",
  "julien baker",
  "la femme",
  "carpenter brut",
  "crystal waters",
  "bunt.",
  "broken bells",
  "butthole surfers",
  "chelsea wolfe",
  "sabrina claudio",
  "slint",
  "nicki nicole",
  "when in rome",
  "borges",
  "john powell",
  "katrina & the waves",
  "re6ce",
  "a-wall",
  "patsy cline",
  "whitney",
  "ugovhb",
  "lisa ono",
  "secos & molhados",
  "philip glass",
  "flow",
  "devon hendryx",
  "ólafur arnalds",
  "casiopea",
  "plvtinum",
  "jon brion",
  "the naked and famous",
  "julia holter",
  "bhad bhabie",
  "dutch melrose",
  "puma blue",
  "rx papi",
  "junny",
  "edith whiskers",
  "lincoln",
  "mild minds",
  "jagwar twin",
  "xamã",
  "joão gomes",
  "jeon soyeon",
  "german error message",
  "anotr",
  "sigrid",
  "jessi",
  "blxst",
  "tom walker",
  "tim bernardes",
  "our last night",
  "public",
  "mc maneirinho",
  "apocalyptica",
  "coda",
  "asteria",
  "luke chiang",
  "silverchair",
  "silver jews",
  "barns courtney",
  "bananarama",
  "hayley williams",
  "fievel is glauque",
  "kyan",
  "sabotage",
  "adam lambert",
  "kimya dawson",
  "y2k",
  "jon pardi",
  "yng lvcas",
  "sugababes",
  "son lux",
  "robert schumann",
  "dawid podsiadło",
  "cartoon",
  "sid",
  "4minute",
  "peach tree rascals",
  "drop nineteens",
  "moondeity",
  "majid jordan",
  "oruam",
  "julie london",
  "mk",
  "damian marley",
  "siinamota",
  "black gryph0n",
  "calle 24",
  "cö shu nie",
  "the dandy warhols",
  "anyma",
  "you me at six",
  "christian nodal",
  "mick jenkins",
  "the devil wears prada",
  "mehro",
  "kaliii",
  "grlwood",
  "ol' dirty bastard",
  "auli'i cravalho",
  "xdinary heroes",
  "public",
  "mc maneirinho",
  "apocalyptica",
  "coda",
  "asteria",
  "luke chiang",
  "silverchair",
  "silver jews",
  "barns courtney",
  "bananarama",
  "hayley williams",
  "fievel is glauque",
  "kyan",
  "sabotage",
  "adam lambert",
  "kimya dawson",
  "y2k",
  "jon pardi",
  "yng lvcas",
  "sugababes",
  "son lux",
  "robert schumann",
  "dawid podsiadło",
  "cartoon",
  "sid",
  "4minute",
  "peach tree rascals",
  "drop nineteens",
  "moondeity",
  "majid jordan",
  "oruam",
  "julie london",
  "mk",
  "damian marley",
  "siinamota",
  "black gryph0n",
  "calle 24",
  "cö shu nie",
  "the dandy warhols",
  "anyma",
  "you me at six",
  "christian nodal",
  "mick jenkins",
  "the devil wears prada",
  "mehro",
  "kaliii",
  "grlwood",
  "ol' dirty bastard",
  "auli'i cravalho",
  "xdinary heroes",
  "lauren spencer smith",
  "summer 2000",
  "kota the friend",
  "black kray",
  "atb",
  "ry x",
  "underoath",
  "sly & the family stone",
  "pink guy",
  "okay kaya",
  "hope sandoval & the warm inventions",
  "morcheeba",
  "semisonic",
  "物語シリーズ",
  "alvaro diaz",
  "still corners",
  "george strait",
  "snap!",
  "missio",
  "the whitest boy alive",
  "drain gang archive",
  "gilbert o'sullivan",
  "connan mockasin",
  "flipp dinero",
  "yu-peng chen",
  "tigers jaw",
  "shouse",
  "salami rose joe louis",
  "porcupine tree",
  "sigala",
  "yandel",
  "kraftwerk",
  "charlie burg",
  "aya nakamura",
  "salt-n-pepa",
  "vtze archive",
  "irene cara",
  "bby goyard",
  "jethro tull",
  "chezile",
  "friday pilots club",
  "weathers",
  "memphis may fire",
  "elle king",
  "milo j",
  "tessa violet",
  "fish in a birdcage",
  "boyscott",
  "matthew wilder",
  "hail the sun",
  "nueki",
  "rodríguez",
  "patrice rushen",
  "jordin sparks",
  "roberta flack",
  "yugo kanno",
  "team sleep",
  "six",
  "plums",
  "yo gotti",
  "rini",
  "breakbot",
  "boy harsher",
  "hunny",
  "rockwell",
  "james newton howard",
  "frances forever",
  "alter bridge",
  "lil dicky",
  "caesars",
  "miúcha",
  "carwash",
  "calle 13",
  "free throw",
  "armani white",
  "rancid",
  "tobi lou",
  "kiana ledé",
  "realestk",
  "run–d.m.c.",
  "george frideric handel",
  "leisure",
  "fresno",
  "miike snow",
  "helvetia",
  "ariel pink",
  "trauma ray",
  "sick legend",
  "will wood and the tapeworms",
  "sistar",
  "while she sleeps",
  "neffex",
  "trashxrl",
  "acid ghost",
  "cashmere cat",
  "leall",
  "lee chae yeon",
  "jaxomy",
  "exaltasamba",
  "clovis reyes",
  "nueki",
  "rodríguez",
  "patrice rushen",
  "jordin sparks",
  "roberta flack",
  "yugo kanno",
  "team sleep",
  "six",
  "plums",
  "yo gotti",
  "rini",
  "breakbot",
  "boy harsher",
  "hunny",
  "rockwell",
  "james newton howard",
  "frances forever",
  "alter bridge",
  "lil dicky",
  "caesars",
  "miúcha",
  "carwash",
  "calle 13",
  "free throw",
  "armani white",
  "rancid",
  "tobi lou",
  "kiana ledé",
  "realestk",
  "run–d.m.c.",
  "george frideric handel",
  "leisure",
  "fresno",
  "miike snow",
  "helvetia",
  "ariel pink",
  "trauma ray",
  "sick legend",
  "will wood and the tapeworms",
  "sistar",
  "while she sleeps",
  "neffex",
  "trashxrl",
  "acid ghost",
  "cashmere cat",
  "leall",
  "lee chae yeon",
  "jaxomy",
  "exaltasamba",
  "clovis reyes",
  "xiu xiu",
  "shinsei kamattechan",
  "gustavo santaolalla",
  "фрози",
  "suzanne vega",
  "cautious clay",
  "royal & the serpent",
  "super junior",
  "jane remover",
  "jxdn",
  "canned heat",
  "hot chelle rae",
  "vangelis",
  "machine head",
  "quinn xcii",
  "dilsinho",
  "enny",
  "luciano",
  "ruby haunt",
  "anthrax",
  "henrik",
  "the pointer sisters",
  "rich gang",
  "blue iverson",
  "slowboy",
  "apashe",
  "perfume",
  "nothing more",
  "dj renan da bl",
  "zutomayo",
  "alex gaudino",
  "nobuo uematsu",
  "sam tinnesz",
  "ritt momney",
  "francis lai",
  "thomas newman",
  "chic",
  "lilly wood and the prick",
  "felipe amorim",
  "travis",
  "sparklehorse",
  "caramella girls",
  "salem ilese",
  "dj joão pereira",
  "cryogeyser",
  "madds buckley",
  "vestron vulture",
  "joy",
  "the calling",
  "jeezy",
  "kim wilde",
  "gnash",
  "florida georgia line",
  "mr. probz",
  "coma cinema",
  "clarissa",
  "nxdia",
  "pomplamoose",
  "sam gellaitry",
  "julio secchin",
  "zack tabudlo",
  "mind’s eye",
  "sam hunt",
  "jazmine sullivan",
  "mø",
  "don henley",
  "mc kevin",
  "digable planets",
  "gims",
  "aldn",
  "lipps inc.",
  "aurbley",
  "hi-c",
  "phillip phillips",
  "rbd",
  "lil pump",
  "reik",
  "scooter",
  "holding absence",
  "billy ocean",
  "gee lee",
  "darondo",
  "lil tracy",
  "$werve",
  "lund",
  "nara leão",
  "tori amos",
  "cix",
  "luh tyler",
  "jessie reyez",
  "sadie jean",
  "jessica keenan wynn",
  "ryan beatty",
  "plenka",
  "hawthorne heights",
  "mc menor mt",
  "nx zero",
  "september",
  "quantic",
  "andrew rannells",
  "earthgang",
  "em beihold",
  "édith piaf",
  "simply red",
  "gang of youths",
  "d-block europe",
  "kanaria",
  "hensonn",
  "broken social scene",
  "gusttavo lima",
  "him",
  "raveena",
  "big l",
  "origami angel",
  "war",
  "the maine",
  "daniel johnston",
  "ryan castro",
  "terno rei",
  "belchior",
  "ritchie valens",
  "ana castela",
  "silversun pickups",
  "kay flock",
  "toshifumi hinata",
  "dj jeeh fdc",
  "dermot kennedy",
  "the chicks",
  "bol4",
  "hoyo-mix",
  "metro station",
  "03 greedo",
  "kristen bell",
  "first aid kit",
  "wiu",
  "thomas rhett",
  "gerard way",
  "the supremes",
  "desiigner",
  "tasha & tracie",
  "janis joplin",
  "la oreja de van gogh",
  "tegan and sara",
  "juicy j",
  "fishmans",
  "cannons",
  "ivri",
  "inner wave",
  "two shell",
  "styx",
  "wwe",
  "sticky fingers",
  "mabel",
  "o rappa",
  "anarbor",
  "linked horizon",
  "looπδ / odd eye circle",
  "crosby, stills & nash",
  "varg²™",
  "toby keith",
  "minor threat",
  "mahmood",
  "agnes obel",
  "emotional oranges",
  "dxrk ダーク",
  "caskets",
  "manny laurenko",
  "rob $tone",
  "soap&skin",
  "yoko takahashi",
  "pale saints",
  "jackson wang",
  "tvxq!",
  "kenny chesney",
  "gala",
  "ivete sangalo",
  "kina",
  "currents",
  "arthur verocai",
  "maximum the hormone",
  "he is we",
  "conway the machine",
  "zella day",
  "thievery corporation",
  "official hige dandism",
  "luna",
  "yunk vino",
  "daughtry",
  "reol",
  "timmy trumpet",
  "yorushika",
  "flight facilities",
  "jamie foxx",
  "everything everything",
  "gza",
  "cher lloyd",
  "bread beatz",
  "gayle",
  "the jam",
  "pearly drops",
  "the righteous brothers",
  "exo-k",
  "cannibal corpse",
  "the struts",
  "lovelytheband",
  "henry mancini",
  "jt music",
  "mrs. green apple",
  "cosmo sheldrake",
  "beirut",
  "ok go",
  "andrew lloyd webber",
  "upsahl",
  "baby bash",
  "john vincent iii",
  "morat",
  "the lovin' spoonful",
  "studio killers",
  "novos baianos",
  "buzzcocks",
  "bobby shmurda",
  "alan jackson",
  "the stooges",
  "gemini",
  "pup",
  "hardrock",
  "katya lel",
  "the specials",
  "the decemberists",
  "joy crookes",
  "yungatita",
  "myles smith",
  "joep beving",
  "smokey robinson & the miracles",
  "ricky nelson",
  "angèle",
  "mupp",
  "ride",
  "key",
  "miss a",
  "jason aldean",
  "sofi tukker",
  "d.o.",
  "ikimonogakari",
  "sharon jones & the dap-kings",
  "dion",
  "tommy james & the shondells",
  "the brian jonestown massacre",
  "don diablo",
  "julien baker",
  "la femme",
  "carpenter brut",
  "crystal waters",
  "bunt.",
  "broken bells",
  "butthole surfers",
  "chelsea wolfe",
  "sabrina claudio",
  "slint",
  "nicki nicole",
  "when in rome",
  "borges",
  "john powell",
  "katrina & the waves",
  "re6ce",
  "a-wall",
  "patsy cline",
  "whitney",
  "ugovhb",
  "lisa ono",
  "secos & molhados",
  "philip glass",
  "flow",
  "devon hendryx",
  "ólafur arnalds",
  "casiopea",
  "plvtinum",
  "jon brion",
  "the naked and famous",
  "julia holter",
  "bhad bhabie",
  "dutch melrose",
  "puma blue",
  "rx papi",
  "junny",
  "edith whiskers",
  "lincoln",
  "mild minds",
  "jagwar twin",
  "xamã",
  "joão gomes",
  "jeon soyeon",
  "german error message",
  "anotr",
  "sigrid",
  "jessi",
  "blxst",
  "tom walker",
  "tim bernardes",
  "our last night",
  "public",
  "mc maneirinho",
  "apocalyptica",
  "coda",
  "asteria",
  "luke chiang",
  "silverchair",
  "silver jews",
  "barns courtney",
  "bananarama",
  "hayley williams",
  "fievel is glauque",
  "kyan",
  "sabotage",
  "adam lambert",
  "kimya dawson",
  "y2k",
  "jon pardi",
  "yng lvcas",
  "sugababes",
  "son lux",
  "robert schumann",
  "dawid podsiadło",
  "cartoon",
  "sid",
  "4minute",
  "peach tree rascals",
  "drop nineteens",
  "moondeity",
  "majid jordan",
  "oruam",
  "julie london",
  "mk",
  "damian marley",
  "siinamota",
  "black gryph0n",
  "calle 24",
  "cö shu nie",
  "the dandy warhols",
  "anyma",
  "you me at six",
  "christian nodal",
  "mick jenkins",
  "the devil wears prada",
  "mehro",
  "kaliii",
  "grlwood",
  "ol' dirty bastard",
  "auli'i cravalho",
  "xdinary heroes",
  "lauren spencer smith",
  "summer 2000",
  "kota the friend",
  "black kray",
  "atb",
  "ry x",
  "underoath",
  "sly & the family stone",
  "pink guy",
  "okay kaya",
  "hope sandoval & the warm inventions",
  "morcheeba",
  "semisonic",
  "物語シリーズ",
  "alvaro diaz",
  "still corners",
  "george strait",
  "snap!",
  "missio",
  "the whitest boy alive",
  "drain gang archive",
  "gilbert o'sullivan",
  "connan mockasin",
  "flipp dinero",
  "yu-peng chen",
  "tigers jaw",
  "shouse",
  "salami rose joe louis",
  "porcupine tree",
  "sigala",
  "yandel",
  "kraftwerk",
  "charlie burg",
  "aya nakamura",
  "salt-n-pepa",
  "vtze archive",
  "irene cara",
  "bby goyard",
  "jethro tull",
  "chezile",
  "friday pilots club",
  "weathers",
  "memphis may fire",
  "elle king",
  "milo j",
  "tessa violet",
  "fish in a birdcage",
  "boyscott",
  "matthew wilder",
  "hail the sun",
  "nueki",
  "rodríguez",
  "patrice rushen",
  "jordin sparks",
  "roberta flack",
  "yugo kanno",
  "team sleep",
  "six",
  "plums",
  "yo gotti",
  "rini",
  "breakbot",
  "boy harsher",
  "hunny",
  "rockwell",
  "james newton howard",
  "frances forever",
  "alter bridge",
  "lil dicky",
  "caesars",
  "miúcha",
  "carwash",
  "calle 13",
  "free throw",
  "armani white",
  "rancid",
  "tobi lou",
  "kiana ledé",
  "realestk",
  "run–d.m.c.",
  "george frideric handel",
  "leisure",
  "fresno",
  "miike snow",
  "helvetia",
  "ariel pink",
  "trauma ray",
  "sick legend",
  "will wood and the tapeworms",
  "sistar",
  "while she sleeps",
  "neffex",
  "trashxrl",
  "acid ghost",
  "cashmere cat",
  "leall",
  "lee chae yeon",
  "jaxomy",
  "exaltasamba",
  "clovis reyes",
  "xiu xiu",
  "shinsei kamattechan",
  "gustavo santaolalla",
  "фрози",
  "suzanne vega",
  "cautious clay",
  "royal & the serpent",
  "super junior",
  "jane remover",
  "jxdn",
  "canned heat",
  "hot chelle rae",
  "vangelis",
  "machine head",
  "quinn xcii",
  "dilsinho",
  "enny",
  "luciano",
  "ruby haunt",
  "anthrax",
  "henrik",
  "the pointer sisters",
  "rich gang",
  "blue iverson",
  "slowboy",
  "apashe",
  "perfume",
  "nothing more",
  "dj renan da bl",
  "zutomayo",
  "alex gaudino",
  "nobuo uematsu",
  "sam tinnesz",
  "ritt momney",
  "francis lai",
  "thomas newman",
  "chic",
  "lilly wood and the prick",
  "felipe amorim",
  "travis",
  "sparklehorse",
  "caramella girls",
  "salem ilese",
  "dj joão pereira",
  "cryogeyser",
  "madds buckley",
  "vestron vulture",
  "joy",
  "the calling",
  "jeezy",
  "kim wilde",
  "gnash",
  "florida georgia line",
  "mr. probz",
  "coma cinema",
  "clarissa",
  "nxdia",
  "pomplamoose",
  "sam gellaitry",
  "julio secchin",
  "zack tabudlo",
  "mind’s eye",
  "sam hunt",
  "jazmine sullivan",
  "mø",
  "don henley",
  "mc kevin",
  "digable planets",
  "gims",
  "aldn",
  "lipps inc.",
  "aurbley",
  "hi-c",
  "phillip phillips",
  "rbd",
  "lil pump",
  "reik",
  "scooter",
  "holding absence",
  "billy ocean",
  "gee lee",
  "darondo",
  "lil tracy",
  "$werve",
  "lund",
  "nara leão",
  "tori amos",
  "cix",
  "luh tyler",
  "jessie reyez",
  "sadie jean",
  "jessica keenan wynn",
  "ryan beatty",
  "plenka",
  "hawthorne heights",
  "mc menor mt",
  "nx zero",
  "september",
  "quantic",
  "andrew rannells",
];
