import React from "react";
import Link from "next/link";
import { cn } from "../../utils";

type CommonProps = {
  className?: string;
  isUpgrade?: boolean;
  href?: string;
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & CommonProps;

export const primaryButtonStyles =
  "tw-inline-flex tw-h-12 tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2 tw-truncate tw-rounded-xl tw-border tw-border-solid tw-border-purple-700 tw-bg-purple-700 tw-px-4 tw-py-3 tw-text-center tw-text-sm tw-font-black tw-text-white tw-transition tw-duration-100 tw-ease-in-out hover:tw-bg-purple-800 disabled:tw-border-none disabled:tw-bg-purple-300 md:tw-px-8";

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    { className, style, isUpgrade, type = "button", disabled, href, ...props },
    ref,
  ) => {
    const buttonStyles = {
      background: isUpgrade
        ? "linear-gradient(45deg, rgba(140,55,213,0.95) 5%, rgba(242,79,225,1) 30%, rgba(255,0,0,0.9) 100%)"
        : undefined,
      border: isUpgrade ? "none" : undefined,
      ...style,
    };

    const commonProps = {
      className: cn(primaryButtonStyles, className),
      style: buttonStyles,
      ...props,
    };

    if (href) {
      return (
        <Link href={href} passHref legacyBehavior>
          <a
            ref={ref as React.Ref<HTMLAnchorElement>}
            {...(commonProps as AnchorProps)}
            {...(props as AnchorProps)}
          />
        </Link>
      );
    }

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={type}
        disabled={disabled}
        {...commonProps}
      />
    );
  },
);

Button.displayName = "Button";

export const secondaryButtonStyles = cn(
  "tw-inline-flex tw-h-12 tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2 tw-truncate tw-rounded-xl tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-px-4 tw-py-3 tw-text-center tw-text-sm tw-font-black tw-text-purple-700 tw-transition tw-duration-100 tw-ease-in-out hover:tw-bg-neutral-50 focus:tw-bg-neutral-100 disabled:tw-cursor-default md:tw-px-8",
);

export const SecondaryButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(({ className, href, ...props }, ref) => {
  const commonProps = {
    className: cn(secondaryButtonStyles, className),
    ...props,
  };

  if (href) {
    return (
      <Link href={href} passHref legacyBehavior>
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...(commonProps as AnchorProps)}
          {...(props as AnchorProps)}
        />
      </Link>
    );
  }

  return <button ref={ref as React.Ref<HTMLButtonElement>} {...commonProps} />;
});

SecondaryButton.displayName = "SecondaryButton";
