import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!);

export async function track(event: string, properties: Record<string, any>) {
  return new Promise<void>((resolve) => {
    mixpanel.track(event, properties, () => {
      resolve();
    });
  });
}

export { mixpanel };
