import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuPortal,
} from "@jammable/ui-components/DropdownMenu";
import DropdownTrigger from "./DropdownTrigger";
import DropdownContent from "./DropdownContent";

const Dropdown = ({ children, dropdownItems, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownTrigger setIsOpen={setIsOpen}>{children}</DropdownTrigger>
      <DropdownMenuPortal>
        <DropdownContent dropdownItems={dropdownItems} className={className} />
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};

export default Dropdown;
