import { ReactNode } from "react";
import BaseLink from "next/link";

export { usePathname, useRouter, useSearchParams } from "next/navigation";

// @TODO temporary workaround to force page reloads from new page to old page
// this avoids tailwindcss breaking legacy pages. Eventually we should use next/link
// for nice clientside transitions

export const customModelUrl = () => `/custommodels`;

export const modelListingUrl = (filter?: string): string =>
  filter ? `/models?filter=${filter}` : `/models`;

export const modelPageUrl = (id: string): string => `/${id}`;

export const modelSearchPageUrl = (term: string): string => `/models?q=${term}`;

export const effectsPageUrl = (id: "sped-up" | "slowed-and-reverb"): string =>
  `/presets/${id}`;

export const pricingUrl = () => `/pricing`;

export const termsUrl = () => `/terms`;
