import { useAuth } from "./AuthProvider";
import { usePrevious } from "@uidotdev/usehooks";
import React, { useContext, useEffect } from "react";
import { setCookie } from "nookies";
import { mixpanel } from "../utils/analytics";

const AnalyticsContext = React.createContext<typeof mixpanel | null>(null);

export function AnalyticsProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const auth: { user?: { uid: string; email: string } } = useAuth();

  const userId = auth.user?.uid;

  const prevUserId = usePrevious(userId);

  useEffect(() => {
    console.log("device_id", mixpanel.get_property("$device_id"));
    // we set this cookie on the client so that we have access to it on the server
    // it is used to associate anonymous user events with a user once authenticated
    // by logging in or signing up
    setCookie(null, "deviceId", mixpanel.get_property("$device_id"));
  });

  useEffect(() => {
    if (!prevUserId && userId) {
      mixpanel.identify(userId);
      mixpanel.people.set({ $email: auth.user?.email });
    } else if (prevUserId && !userId) {
      mixpanel.reset();
    }
  }, [prevUserId, userId, auth.user]);

  return (
    <AnalyticsContext.Provider value={mixpanel}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error("Must wrap consumer with an <AnalyticsProvider />");
  }

  return ctx;
}
