import { cn } from "@jammable/ui-core";
import { MediaSrc, getUrlForSrc } from "./shared";
import mic from "./icons/mic.svg";
import note from "./icons/note.svg";
import voice from "./icons/voice.svg";
import voiceWhite from "./icons/voicewhite.svg";
import link from "./icons/link.svg";
import youtube from "./icons/youtube.svg";
import tiktok from "./icons/tiktok.svg";
import instagram from "./icons/instagram.svg";
import recordVideo from "./icons/record-video.webp";

// @TODO revisit how we are centralising custom icons to be more efficient
// and more like react-icons
const icons: { [key: string]: MediaSrc } = {
  mic,
  note,
  voice,
  voiceWhite,
  link,
  youtube,
  tiktok,
  instagram,
  recordVideo,
};

export function Icon({
  icon,
  src,
  alt,
  width,
  height,
  className,
}: {
  className?: string;
  icon?: string;
  src?: MediaSrc;
  alt?: string;
  width?: string;
  height?: string;
  size?: number;
}) {
  const url = getUrlForSrc((icon ? icons[icon] : src) as MediaSrc);
  return (
    <img
      loading="lazy"
      decoding="async"
      src={url}
      alt={alt}
      width={width}
      height={height}
      className={cn("tw-inline-block", className)}
    />
  );
}
