import { Button, SecondaryButton } from "@jammable/ui-core";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import {
  UsersRound,
  MicVocal,
  House,
  BadgePlus,
  CircleDollarSign,
  Gem,
} from "lucide-react";

const MobileMenu = ({
  mobileMenuOpen,
  toggleMobileMenu,
  isAuthenticated,
  isCinematic,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const menuItems = [
    { href: "/", label: "Home", icon: <House size={20} strokeWidth={3} /> },
    {
      href: "/models",
      label: "Voice Library",
      icon: <MicVocal size={20} strokeWidth={3} />,
    },
    {
      href: "/train",
      label: "Train Voice",
      icon: <BadgePlus size={20} strokeWidth={3} />,
    },
    {
      href: "/duets",
      label: "Duets",

      icon: <UsersRound size={20} strokeWidth={3} />,
    },
    {
      href: "/pricing",
      label: "Pricing",
      icon: <Gem size={20} strokeWidth={3} />,
    },
    {
      href: "https://affiliate.jammable.com",
      label: "Affiliate Program",
      icon: <CircleDollarSign size={20} strokeWidth={3} />,
    },
  ];

  const pathname = usePathname();

  const animationClass = (index) => `
    tw-transform tw-transition-all tw-duration-300 tw-ease-in-out
    ${
      mounted && mobileMenuOpen
        ? "tw-translate-y-0 tw-opacity-100"
        : "tw-translate-y-[-20px] tw-opacity-0"
    }
    tw-delay-[${index * 50}ms]
  `;

  return (
    <div
      className={`tw-fixed tw-flex md:tw-hidden tw-top-[65px] md:tw-top-[65px] tw-w-full ${isCinematic ? "tw-bg-slate-950/95 tw-top-[105px] tw-rounded-3xl tw-backdrop-blur tw-text-white" : "tw-bg-white/90 tw-backdrop-blur"} tw-z-[1000]  tw-flex-col tw-p-5 tw-h-[calc(100vh)] tw-overflow-y-auto
                  tw-transition-all tw-duration-300 tw-ease-in-out
                  ${mobileMenuOpen ? "tw-opacity-100" : "tw-opacity-0 tw-pointer-events-none"}`}
    >
      <div className="tw-flex tw-flex-col tw-gap-4 tw-text-lg tw-font-bold">
        {menuItems.map((item, index) => (
          <Link
            key={item.href}
            href={item.href}
            onClick={toggleMobileMenu}
            className={`${animationClass(index)}
                        ${pathname === item.href && "tw-text-purple-700"}
                        hover:tw-text-purple-700 tw-flex tw-items-center tw-gap-2 tw-text-center   tw-font-black tw-text-md `}
          >
            <span className="tw-flex">{item.icon}</span>
            <span>{item.label}</span>
          </Link>
        ))}
        {!isAuthenticated && (
          <SecondaryButton
            onClick={toggleMobileMenu}
            href={`/login`}
            className={`${animationClass(menuItems.length)} tw-rounded-full`}
          >
            Log In
          </SecondaryButton>
        )}
        {!isAuthenticated && (
          <Button
            onClick={toggleMobileMenu}
            href={`/signup`}
            className={`${animationClass(menuItems.length + 1)} tw-rounded-full`}
          >
            Sign Up
          </Button>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
