import React from "react";
import { DropdownMenuTrigger } from "@jammable/ui-components/DropdownMenu";
import { ChevronDown } from "lucide-react";

const DropdownTrigger = ({ children, setIsOpen }) => (
  <DropdownMenuTrigger
    onPointerDown={(e) => e.preventDefault()}
    onClick={() => setIsOpen((prev) => !prev)}
    asChild
  >
    {children}
  </DropdownMenuTrigger>
);

export default DropdownTrigger;
