import { getAuthHeaders } from "../shared";

export type SubscriptionUpgradeType =
  | "Jammable Creator"
  | "Jammable Power User";

export async function mutationSubscriptionUpgrade(
  type: SubscriptionUpgradeType = "Jammable Creator",
): Promise<void> {
  const response = await fetch(`/api/payment/subscription`, {
    method: "POST",
    headers: { ...(await getAuthHeaders()) },
    body: JSON.stringify({
      plan: type,
    }),
  });

  if (response.status != 200) {
    throw new Error(`${response?.statusText}`);
  }

  const data = await response.json();
  return data;
}

export async function mutationCreateSubscriptionIntent({
  priceId,
  refId,
  from,
  currency,
}: {
  priceId: string;
  refId?: string;
  currency?: string;
  from?: string;
}): Promise<{
  clientSecret: string;
}> {
  const response = await fetch(`/api/payment/createSubscriptionIntent`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(await getAuthHeaders()),
    },
    body: JSON.stringify({
      priceId,
      refId,
      currency,
      from,
    }),
  });

  if (response.status != 200) {
    throw new Error(`${response?.statusText}`);
  }

  const data = await response.json();
  return data;
}
