import { ReactNode } from "react";
import { cn } from "../utils";

export function CinematicLayout({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "tw-min-h-screen tw-w-screen tw-overflow-hidden tw-bg-gradient-cinematic-radial",
        className,
      )}
    >
      {children}
    </div>
  );
}
