import { HTMLAttributes, ReactNode } from "react";
import { default as NextImage } from "next/image";
import { cn } from "@jammable/ui-core";
import { MediaSrc, getUrlForSrc } from "./shared";
import bannerImg from "@jammable/ui-components/Media/backgrounds/banner.png";

let BaseImage = NextImage;

export const UnoptimizedImage = (props: HTMLAttributes<HTMLImageElement>) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img {...props} />
);

interface Image {
  src: MediaSrc;
  alt?: string;
  width?: number;
  height?: number;
  priority?: boolean;
  blurDataURL?: string;
  placeholder?: "blur" | "empty";
}

export function Image({
  src,
  alt,
  width = 200,
  height = 200,
  priority = false,
  className,
  fill = false,
  ...props
}: {
  fill?: boolean;
  className?: HTMLAttributes<HTMLImageElement>["className"];
  style?: HTMLAttributes<HTMLImageElement>["style"];
} & Image) {
  const url = getUrlForSrc(src);
  return (
    <BaseImage
      style={{ width: fill ? "100%" : "" }}
      priority={priority}
      src={url}
      alt={alt!}
      width={width}
      height={height}
      className={className}
      {...props}
    />
  );
}

export function BackgroundImage({
  src,
  children,
  className,
  positionClassName = "tw-left-1/2 tw-top-1/2 tw-z-0 -tw-translate-x-1/2 -tw-translate-y-1/2",
  width,
  height,
  alt = "",
  priority = false,
  ...props
}: {
  children: ReactNode;
  className?: string;
  positionClassName?: string;
} & Image) {
  const url = getUrlForSrc(src);
  return (
    <div className={cn("tw-relative tw-overflow-hidden", className)}>
      <BaseImage
        priority={priority}
        className={cn("tw-absolute ", positionClassName)}
        src={url}
        width={width}
        height={height}
        alt={alt}
        style={{ maxWidth: "initial" }}
        {...props}
      />
      <div className="tw-relative">{children}</div>
    </div>
  );
}

export function BackgroundImageMain({
  children,
  className,
  positionClassName = "tw-left-1/2 tw-top-1/2 tw-z-0 -tw-translate-x-1/2 -tw-translate-y-1/2",
  width,
  height,
  alt = "",
  priority = false,
}: {
  children: ReactNode;
  className?: string;
  positionClassName?: string;
} & Image) {
  return (
    <div className={cn("tw-relative tw-overflow-hidden", className)}>
      <NextImage
        priority={priority}
        className={cn("tw-absolute ", positionClassName)}
        src={bannerImg}
        width={width}
        height={height}
        alt={alt}
        style={{ maxWidth: "initial" }}
        placeholder="blur"
      />
      <div className="tw-relative">{children}</div>
    </div>
  );
}
/**
 * For usage outside Next, so images work
 * e.g Storybook
 */
export function unoptimizeNextImageForStorybook() {
  //@ts-expect-error - image manipulation on purpose
  BaseImage = UnoptimizedImage;
}
