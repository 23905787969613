import { z } from "zod";

const ZEnv = z.enum(["development", "production", "preview", "test"]);

export const ENV = ZEnv.parse(
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
    process.env.VERCEL_ENV ||
    process.env.NODE_ENV,
);

export const isProduction = ENV === "production";
