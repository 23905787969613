import Link from "next/link";
import React from "react";
import { IoMdMail } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { useAuth } from "../../context/AuthProvider";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { useQuery } from "react-query";

const MessageIcon = () => {
  const fetchUnreadCount = async (updateUser) => {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    const response = await axios.get("/api/message/getUnreadMessageCount", {
      headers: { Authorization: token },
    });
    updateUser({
      unreadCount: response.data.unreadCount,
    });
    return response.data.unreadCount;
  };

  const { user, updateUser } = useAuth();

  useQuery(
    ["unreadCount", user?.firebaseUid],
    () => fetchUnreadCount(updateUser),
    {
      enabled: !!user?.firebaseUid,
      //   refetchInterval: 60000,
      refetchOnWindowFocus: true,
    },
  );

  return (
    <Link
      href="/messages"
      className="tw-cursor-pointer tw-relative tw-flex tw-rounded-full"
    >
      <HiMail size={26} />
      {user?.unreadCount > 0 && (
        <span className="tw-absolute tw--top-1 tw--right-1 tw-bg-purple-700 tw-text-white tw-rounded-full tw-font-black  tw-size-[18px] tw-flex tw-items-center tw-justify-center tw-text-xs">
          {user?.unreadCount}
        </span>
      )}
    </Link>
  );
};

export default MessageIcon;
