import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-hot-toast";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { BASE_URL } from "../utils/tools";
import { Button } from "@jammable/ui-core";
import { Spinner } from "@jammable/ui-components/Loader";

const StripePaymentElement = ({ price, discount, symbol, newLayout }) => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const elements = useElements();

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {});
      element.on("change", () => {
        setError(null);
      });
    }
  }, [elements]);

  async function handleCheckoutFormSubmit(event) {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const stripePayload = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${BASE_URL}/thankyou`,
      },
    });
    setLoading(false);
    if (stripePayload.error) {
      setLoading(false);
      // dispatch(actions.errorGlobal(stripePayload.error.message));
      if (stripePayload?.error?.message === "Your card has been declined.") {
        setError(
          "Your card has been declined. Please contact your bank or try a different payment method.",
        );
      } else {
        setError(stripePayload?.error?.message);
      }
    } else {
      toast.success(`Thank you for your purchase!`);
    }
    setLoading(false);
  }

  return (
    <form style={{ marginBottom: 0 }} onSubmit={handleCheckoutFormSubmit}>
      {error && (
        <div
          style={{ marginBottom: 20, padding: 15 }}
          className="error-message"
        >
          {error}
        </div>
      )}

      <div style={{ minHeight: "200px" }}>
        <PaymentElement />
      </div>

      <Button
        shadow
        style={{
          backgroundImage: "linear-gradient(90deg, #9a4de0 0%, #5c24b6 100%)",
          zIndex: 10000,
        }}
        auto
        className="tw-text-center tw-w-full tw-rounded-full tw-flex tw-justify-center tw-items-center"
        type="submit"
      >
        {loading ? (
          <Spinner className="tw-border-white" size={4} />
        ) : (
          <div className="tw-flex tw-items-center tw-gap-2">
            <BsFillCreditCard2BackFill size={20} />
            <div>
              Pay{" "}
              <span
                style={{
                  color: discount && "lightgreen",
                }}
              >
                {symbol}
                {price}
              </span>{" "}
              {discount && (
                <span style={{ textDecoration: "line-through" }}>
                  {" "}
                  {symbol}
                  {discount}
                </span>
              )}{" "}
            </div>
          </div>
        )}
      </Button>
    </form>
  );
};

export default StripePaymentElement;
