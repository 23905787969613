const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

export default function cloudflareLoader({ src, width, quality }) {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  params.push(`format=webp`);
  const paramsString = params.join(",");
  return process.env.NODE_ENV === "development"
    ? `https://jammable.com/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
    : `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
}
