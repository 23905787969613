import { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Dialog, DialogContent } from "@jammable/ui-components/Dialog";

const ModalContext = createContext();

export const useModal = () => {
  return useContext(ModalContext);
};

const ModalRenderer = ({ modal, closeModal }) => {
  const modalEntries = Object.entries(modal).filter(
    ([name, config]) => config.isOpen,
  );

  return (
    <>
      {modalEntries.map(([name, config], index) => {
        const isTopModal = index === modalEntries.length - 1;
        const opacity =
          name === "generate-image-modal"
            ? 1
            : modalEntries.length > 1
              ? 0.1
              : 1;

        return (
          <Dialog
            aria-hidden={false}
            open={true}
            key={name}
            preventClose={name === "create-video-modal" ? true : false}
            closeButton={name === "create-video-modal" ? true : false}
            onOpenChange={(e) => {
              if (e === false) {
                closeModal(name);
              }
            }}
          >
            <DialogContent
              style={{ opacity: opacity > 0 ? opacity : 0.1 }} // Ensure opacity doesn't go below 0.1
              className={`md:tw-min-w-[450px]  md:tw-max-w-lg tw-rounded-t-lg ${index === 1 && "tw-shadow-xl"}`}
              drawerMode
            >
              {config.content}
            </DialogContent>
          </Dialog>
        );
      })}
    </>
  );
};

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({});

  const openModal = (name, content) => {
    setModal({ ...modal, [name]: { isOpen: true, content } });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: { isOpen: false, content: null } });
    document.body.style.overflow = "auto";
  };

  const closeAllModals = () => {
    const newModalState = { ...modal };
    for (const name in newModalState) {
      newModalState[name].isOpen = false;
    }
    document.body.style.overflow = "auto";
    setModal(newModalState);
  };

  const onRouteChange = () => {
    closeAllModals();
  };

  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", onRouteChange);

    return () => {
      router.events.off("routeChangeComplete", onRouteChange);
    };
  }, [modal]);

  const value = { modal, openModal, closeModal };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalRenderer modal={modal} closeModal={closeModal} />
    </ModalContext.Provider>
  );
};
