import { ReactNode } from "react";
import { cn } from "../utils";

export function NarrowLayout({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "tw-mx-auto tw-w-full tw-max-w-[800px] tw-p-2 tw-pt-24 tw-duration-1000 tw-animate-in tw-fade-in",
        className,
      )}
    >
      {children}
    </div>
  );
}
