import "@jammable/ui-core/styles/globals.css";
import "@jammable/ui-core/styles/legacy-globals.css";

import { ThemeProvider } from "next-themes";
import Layout from "../components/Layout";
import { AuthProvider } from "../context/AuthProvider";
import { useEffect } from "react";
import nProgress from "nprogress";
import "../styles/nprogress.css";
import { useRouter } from "next/router";
import { ModalProvider } from "../context/ModalContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import Script from "next/script";
import localFont from "next/font/local";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { appWithTranslation } from "next-i18next";
import { AnalyticsProvider } from "../context/AnalyticsContext";
import { Toaster } from "react-hot-toast";
import posthog from "posthog-js";
import { CSPostHogProvider } from "../context/PostHogContext";
import GlobalAudioPlayer from "../components/GlobalAudioPlayer";

const gilmer = localFont({
  src: [
    {
      path: "../assets/fonts/GilmerRegular.otf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../assets/fonts/GilmerMedium.otf",
      weight: "600",
      style: "bold",
    },
    {
      path: "../assets/fonts/GilmerBold.otf",
      weight: "800",
      style: "bolder",
    },
    {
      path: "../assets/fonts/GilmerHeavy.otf",
      weight: "900",
    },
  ],
});

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  useEffect(() => {
    nProgress.configure({ easing: "ease", speed: 500 });
    const handleStart = () => nProgress.start();

    const handleComplete = () => nProgress.done();

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  return (
    <div className={`${gilmer.className}`}>
      <ThemeProvider attribute="class" defaultTheme="light">
        <Toaster />
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          />
        </Head>
        <Script
          id="google-tag-manager"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-5KTN4FJ7');`,
          }}
        />
        <Script
          strategy="lazyOnload"
          async
          src="https://cdn.tolt.io/tolt.js"
          data-tolt="e257e435-52b6-4337-a81a-c596adaa7b3a"
        ></Script>

        <Script id="churnkey" strategy="lazyOnload">
          {`
          !function(){  
            if (!window.churnkey || !window.churnkey.created) {
              window.churnkey = { created: true };
              const a = document.createElement('script');
              a.src = 'https://assets.churnkey.co/js/app.js?appId=a2nyu1mx5';
              a.async = true;
              const b = document.getElementsByTagName('script')[0];
              b.parentNode.insertBefore(a, b);
            }
          }();
        `}
        </Script>
        <QueryClientProvider client={queryClient}>
          <GlobalAudioPlayer>
            <AuthProvider>
              <CSPostHogProvider>
                <AnalyticsProvider>
                  <ModalProvider>
                    <GoogleAnalytics
                      gaMeasurementId="G-TBSH1D6SKK"
                      trackPageViews
                    />
                    <DefaultSeo
                      title="Jammable | Create AI Covers With Your Favorite Voices"
                      description="Create AI covers using AI in seconds with Jammable, with thousands of community uploaded AI voice models available for creative use now!"
                      themeColor="#782DC8"
                      openGraph={{
                        title:
                          "Jammable | Create AI Covers with Your Favorite Voices | AI-Powered Music Creation",
                        description:
                          "Create AI covers using AI in seconds with Jammable, with thousands of community uploaded AI voice models available for creative use now!",
                        type: "website",
                        locale: "en_IE",
                        siteName: "Jammable AI",
                      }}
                      twitter={{
                        handle: "@handle",
                        site: "@site",
                        cardType: "summary_large_image",
                      }}
                    />
                    {/* line 72 is not working, can't get NextUI to use the Gilmer Next/Font so we have to force it this way,
                      as the plan is to ditch NextUI we can live with it for now */}
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
                        * {
                          font-family: ${gilmer.style.fontFamily} !important;
                        }`,
                      }}
                    />
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  </ModalProvider>
                </AnalyticsProvider>
              </CSPostHogProvider>
            </AuthProvider>
          </GlobalAudioPlayer>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default appWithTranslation(MyApp);
