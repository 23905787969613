import Ellipse from "@/svgs/Ellipse";
import Image from "next/image";
import React, { useState } from "react";
import note from "../assets/note.png";

const FreeConversionLimit = ({ remaining, dark, used }) => {
  const [freeConversionsArray, setFreeConversionsArray] = useState(
    Array.from({ length: remaining }, (_, index) => ({
      id: index + 1,
      name: `Item ${index + 1}`,
      used: false,
    })),
  );

  const freeConversionsUsedArray = Array.from({ length: used }, (_, index) => ({
    id: index + 1,
    name: `Item ${index + 1}`,
  }));

  const handleUseConversion = (index) => {
    setFreeConversionsArray((prev) =>
      prev.map((item, idx) => (idx === index ? { ...item, used: true } : item)),
    );
    setTimeout(() => {
      setFreeConversionsArray((prev) => prev.filter((_, idx) => idx !== index));
    }, 500); // duration of the animation
  };

  return (
    <div className="tw-flex tw-gap-1">
      {freeConversionsArray.slice(0, 3).map((item, idx) => (
        <div
          key={idx}
          className={`tw-flex tw-relative tw-items-center tw-justify-center ${
            item.used ? "fade-out" : ""
          }`}
          onClick={() => handleUseConversion(idx)}
        >
          <Ellipse />
          <Image
            className="tw-absolute"
            src={note}
            width={26}
            height={26}
            quality={100}
            alt="Free Daily Conversion Token"
          />
        </div>
      ))}

      {freeConversionsUsedArray.slice(0, 3).map((item, idx) => (
        <div
          key={idx}
          className={`${dark ? "tw-bg-white/10" : "tw-bg-gray-100"} tw-rounded-3xl`}
          style={{ width: 45, height: 45 }}
        />
      ))}
    </div>
  );
};

export default FreeConversionLimit;
