import React from "react";
import {
  DropdownMenuContent,
  DropdownMenuPortal,
} from "@jammable/ui-components/DropdownMenu";

const DropdownContent = ({ dropdownItems, className }) => (
  <DropdownMenuPortal>
    <DropdownMenuContent
      className={`tw-z-50 tw-flex tw-flex-col tw-gap-1 tw-rounded-3xl tw-shadow-xl tw-border tw-border-solid tw-border-gray-200 ${className}`}
    >
      {dropdownItems}
    </DropdownMenuContent>
  </DropdownMenuPortal>
);

export default DropdownContent;
