import { ReactNode } from "react";
import { Button, cn } from "@jammable/ui-core";
import { Icon } from "@jammable/ui-components/Media";
import micGif from "./assets/mic.webp";
import { Spinner } from "@jammable/ui-components/Loader";
import { useUpgradeOptions } from "./hooks/useUpgradeOptions";
import { PlanSelector } from "./components/PlanSelector";
import FreeConversionLimit from "../../../../../apps/web/components/FreeConversionLimit";
import cool from "./assets/cool.png";
import Image from "next/image";
import { useAuth } from "../../../../../apps/web/context/AuthProvider";
import { BsChevronDown } from "react-icons/bs";

interface SubscribeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- temporary whilst we use the old checkout
  renderCheckout: (props: any) => ReactNode;
  fallback?: () => void;
  callback?: () => void;
  message?: { message?: string; item?: string };
  quota?: any;
  hasSidebar?: boolean;
  from?: string;
}

export function Subscribe({
  fallback,
  callback,
  renderCheckout,
  message,
  hasSidebar,
  from,
}: SubscribeProps) {
  const {
    isLoadingPrices,
    isUpgrading,
    showCheckout,
    checkoutParams,
    currency,
    upgradeOptions,
    selectedPlanName,
    handleSelectPlan,
    handleNewSubscription,
  } = useUpgradeOptions({ fallback, callback, from });
  const { user } = useAuth();
  const quota = user?.quota;

  const getMessage = () => {
    if (
      quota?.planName === "free" &&
      quota?.conversions?.remainingFreeCredits === 0
    ) {
      return {
        message: "You have reached your daily limit...",
        item: "daily-limit",
      };
    }
  };

  return (
    <section
      className={cn(
        "tw-transition-[width] tw-duration-100 ",
        { "md:tw-w-[400px]": showCheckout && checkoutParams },
        { "md:tw-w-[790px]": !(showCheckout && checkoutParams) },
      )}
    >
      <>
        <div className="tw-flex tw-min-h-72 tw-flex-col md:tw-flex-row  ">
          {hasSidebar && !showCheckout && (
            <div className="tw-relative tw-pb-0 md:tw-pb-5 tw-flex tw-w-full tw-flex-col tw-p-5 tw-text-left tw-tracking-tight md:tw-w-1/2 md:tw-text-left">
              <h2 className="tw-m-0 tw-mb-4 tw-text-2xl tw-font-black tw-text-black md:tw-mx-0 md:tw-max-w-96 md:tw-text-3xl">
                {getMessage()?.message
                  ? getMessage()?.message
                  : "You don't have enough credits"}
              </h2>

              {/* <p className="tw-m-0 tw-hidden tw-font-bold tw-leading-tight md:tw-block">
                1 credit = 1 cover
              </p> */}
              <div className="tw-hidden tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-3 md:tw-flex">
                <div className="tw-font-bold">Free Daily Conversions</div>
                <FreeConversionLimit
                  used={quota?.conversions?.usage?.freeUsage?.used}
                  remaining={quota?.conversions?.remainingFreeCredits}
                  dark={false}
                />
              </div>
              <div className="tw-hidden tw-w-full tw-gap-3 sm:tw-flex-col md:tw-flex ">
                <div className="tw-rounded-xl tw-border tw-border-solid tw-border-black/10 tw-p-4 tw-opacity-50 ">
                  <div className="tw-font-bold tw-h-10 tw-flex tw-items-center tw-w-full tw-justify-between">
                    Advanced Settings
                    <BsChevronDown strokeWidth={1.5} />
                  </div>
                </div>
                <div className="tw-flex tw-w-full tw-h-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-400 tw-p-3 tw-font-bold tw-text-white tw-opacity-50">
                  Convert!
                </div>
              </div>
            </div>
          )}
          <PlanSelector
            selectedPlanName={selectedPlanName}
            upgradeOptions={upgradeOptions}
            currency={currency}
            handleSelectPlan={handleSelectPlan}
            hasSidebar={false}
            handleNewSubscription={handleNewSubscription}
            showCheckout={showCheckout}
            checkoutParams={checkoutParams}
            isUpgrading={isUpgrading}
            isLoadingPrices={isLoadingPrices}
            message={message}
          />
        </div>
        {/* <div className="tw-mt-3 tw-flex tw-flex-col tw-gap-2 tw-pt-2 tw-text-center sm:tw-pt-8">
            <Button
              className="tw-h-14 tw-w-full tw-font-bold"
              onClick={handleNewSubscription}
              disabled={isUpgrading || isLoadingPrices}
              style={{
                backgroundImage:
                  "linear-gradient(90deg, #9a4de0 0%, #5c24b6 100%)",
                boxShadow: "#782dc8 0px 4px 32px 0px",
                border: "none",

                borderRadius: 32,
              }}
            >
              {isUpgrading ? (
                <Spinner className="tw-mt-1.5 tw-size-5 tw-border-white" />
              ) : (
                `Continue`
              )}
            </Button>
            <p className="tw-mt-1 tw-text-sm tw-font-bold tw-leading-tight">
              No commitment - cancel anytime!
            </p>
          </div> */}
      </>
    </section>
  );
}
